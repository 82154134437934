//layout
// ラッパー
#wrapper {
    width: 100%;
    min-width: 1000px;
    overflow: hidden;
}

@include for-tb() {
    #wrapper {
        min-width: 0;
    }
}

// ヘッダー
header {
    height: 140px;
    padding: 0 3vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 999;
    @media screen and (max-width: 1360px) {
        padding: 0 2vw;
    }
    @media screen and (max-width: 1200px) {
        height: 100px;
    }
    @include for-sp() {
        height: 80px;
        padding: 0 4vw;
    }
}

.logo {
    padding-bottom: 10px;
    img {
        width: 302px;
    }
    @media screen and (max-width: 1200px) {
        width: 240px;
        img {
            @include max(w);
        }
    }
    @include for-sp() {
        width: 220px;
    }
}

// グローバルナビ
#menu-btn {
    display: none;
}

@include for-tb() {
    #menu-btn {
        display: block;
        width: 60px;
        height: 60px;
        background: $RED;
        border-radius: 8px;
        position: fixed;
        right: 20px;
        top: 20px;
        cursor: pointer;
        z-index: 999;
        overflow: hidden;
        .menu-line {
            display: block;
            width: 30px;
            height: 2px;
            border-radius: 1px;
            background: #fff;
            position: absolute;
            left: calc(50% - 15px);
            &:nth-child(1) {
                top: 21px;
                transition: top 0.1s $ease2 0.2s, transform 0.2s $ease2 0.05s;
            }
            &:nth-child(2) {
                top: 29px;
                transition: transform 0.1s $ease2 0s;
            }
            &:nth-child(3) {
                top: 37px;
                transition: top 0.1s $ease2 0.2s, transform 0.2s $ease2 0.05s;
            }
        }
    }
    #menu.open {
        #menu-btn {
            .menu-line {
                &:nth-child(1) {
                    top: 29px;
                    transform: rotate(-135deg);
                    transition: top 0.1s $ease2 0.05s, transform 0.2s $ease2 0.1s;
                }
                &:nth-child(2) {
                    transform: scaleX(0);
                }
                &:nth-child(3) {
                    top: 29px;
                    transform: rotate(135deg);
                    transition: top 0.1s $ease2 0.05s, transform 0.2s $ease2 0.1s;
                }
            }
        }
        .navi-wrap {
            visibility: visible;
            opacity: 1;
        }
    }
}

@include for-sp() {
    #menu-btn {
        width: 50px;
        height: 50px;
        right: 5px;
        top: 10px;
        .menu-line {
            width: 26px;
            left: calc(50% - 13px);
            &:nth-child(1) {
                top: 16px;
            }
            &:nth-child(2) {
                top: 24px;
            }
            &:nth-child(3) {
                top: 32px;
            }
        }
    }
    #menu.open {
        #menu-btn {
            .menu-line {
                &:nth-child(1),
                &:nth-child(3) {
                    top: 24px;
                }
            }
        }
    }
}

.navi-wrap {
    display: flex;
    align-items: center;
    @include for-tb() {
        display: block;
        visibility: hidden;
        opacity: 0;
        transition: all 0.5s $ease5;
        width: 100%;
        height: 100%;
        padding: 80px 10vw;
        background: rgba($RED, 0.85);
        backdrop-filter: blur(3px);
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        position: fixed;
        z-index: 998;
        top: 0;
        left: 0;
    }
}

.gnavi {}

.gnavi-list {
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
    position: relative;
    @include for-tb() {
        flex-direction: column;
        margin-right: 0;
        justify-content: center;
        align-items: flex-start;
        padding-bottom: 60px;
        &:before {
            content: "";
            width: 60px;
            height: 1px;
            background: #fff;
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }
    @include for-sp() {
        padding-bottom: 0;
        &:before {
            display: none;
        }
    }
    li {
        text-align: center;
        position: relative;
        line-height: 1;
        @include for-tb() {
            margin-top: 40px;
        }
        @include for-sp() {
            margin-top: 24px;
        }
        &:before {
            content: "";
            width: 1px;
            height: 12px;
            background: $BDC;
            position: absolute;
            left: 0;
            top: 3px;
            transform: scaleX(0.6);
            /*@media screen and (max-width:1200px){
				display: none;
			}*/
        }
        &:first-child:before {
            display: none;
        }
        /*&:nth-child(5){
			a:before{background: #fff;}
		}*/
    }
    a {
        display: block;
        color: $BLACK;
        padding: 0 27px;
        position: relative;
        transition: opacity 0.3s linear;
        @media screen and (max-width: 1550px) {
            padding: 0 20px;
        }
        @media screen and (max-width: 1400px) {
            padding: 0 16px;
        }
        @media screen and (max-width: 1250px) {
            padding: 0 12px;
        }
        @media screen and (max-width: 1150px) {
            padding: 0 8px;
        }
        @media screen and (max-width: 1000px) {
            padding: 0 4px;
        }
        @include for-tb() {
            color: #fff;
            display: flex;
            flex-direction: column-reverse;
            align-items: flex-start;
            padding: 0;
        }
        &:before {
            content: "";
            width: 40px;
            height: 1px;
            background: $RED;
            position: absolute;
            left: 50%;
            top: 26px;
            transform: translateX(-50%);
            opacity: 0;
            transition: opacity $hover, width 0.2s $ease2;
            @include for-tb() {
                display: none;
            }
        }
    }
    .active a,
    a:hover {
        text-decoration: none;
        &:before {
            opacity: 1;
            width: 20px;
        }
    }
    a:hover {
        opacity: 0.8;
    }
}

.gnavi-jp {
    display: block;
    font-size: 1.6rem;
    font-weight: 700;
    @media screen and (max-width: 1360px) {
        font-size: 1.5rem;
    }
    @media screen and (max-width: 1100px) {
        font-size: 1.4rem;
    }
    @media screen and (max-width: 1050px) {
        font-size: 1.3rem;
    }
    @include for-tb() {
        font-size: 1.7rem;
        margin-top: 10px;
    }
    @include for-sp() {
        font-size: 1.2rem;
        margin-top: 6px;
    }
}

.gnavi-en {
    @extend .prompt;
    font-size: 1.3rem;
    letter-spacing: 0.05em;
    display: block;
    margin-top: 17px;
    @media screen and (max-width: 1360px) {
        font-size: 1.1rem;
    }
    @include for-tb() {
        font-size: 4.4rem;
        margin-top: 0;
    }
    @include for-sp() {
        font-size: 2.4rem;
    }
}

.gnavi-contact-en {
    margin-top: 7.5px;
    @include for-tb() {
        margin-top: 0;
    }
}

.head-contact {
    text-align: center;
    margin-right: 10px;
    @media screen and (max-width: 1360px) {
        width: 170px;
        margin-right: 0;
    }
    @include for-tb() {
        margin-top: 60px;
        display: flex;
        width: 100%;
        align-items: center;
    }
    @include for-sp() {
        margin-top: 60px;
        display: block;
    }
}

.head-contact-btn {
    .btn-head-contact {
        display: block;
        text-align: center;
        font-size: 1.8rem;
        background: $FONT_COLOR;
        color: #fff;
        line-height: 1;
        width: 198px;
        padding: 15px 0;
        border-radius: 8px;
        transition: all $hover;
        @media screen and (max-width: 1360px) {
            width: 170px;
        }
        @media screen and (max-width: 1200px) {
            font-size: 1.3rem;
        }
        @include for-tb() {
            font-size: 2.1rem;
            width: auto;
            padding: 16px 20px;
            background: #fff;
            color: $RED;
            font-weight: 700;
        }
        @include for-sp() {
            width: 100%;
            font-size: 1.6rem;
            padding: 12px 0;
        }
        &:hover {
            text-decoration: none;
            background: #555;
            text-shadow: 0 0 6px rgba(#fff, 0.2);
        }
    }
}

.head-tel {
    @extend .prompt;
    font-size: 2.8rem;
    letter-spacing: 0.02em;
    color: #fff;
    @media screen and (max-width: 1360px) {
        font-size: 2.3rem;
    }
    @include for-tb() {
        margin-left: 30px;
        font-size: 4rem;
        line-height: 1;
        border-bottom: 1px solid #fff;
        padding-bottom: 3px;
        &:before {
            content: "tel. ";
            font-size: 2rem;
        }
    }
    @include for-sp() {
        width: 100%;
        font-size: 2.8rem;
        margin-left: 0;
        margin-top: 20px;
        border: none;
        a {
            color: #fff;
        }
    }
}

article {}

.cta {
    padding: 120px 0 145px;
    @include for-sp() {
        padding: 60px 0;
    }
}

.cta-tit {
    text-align: center;
    font-size: 2.4rem;
    color: $BLACK;
    letter-spacing: 0.12em;
    @include for-sp() {
        font-size: 1.8rem;
        line-height: 1.5;
    }
}

.cta-btn {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    @include for-tb() {
        width: 90vw;
        margin: 25px auto 0;
    }
    @include for-sp() {
        width: 80vw;
        display: block;
        margin: 70px auto;
    }
}

.cta-contact {
    margin-right: 30px;
    height: 100px;
    width: 464px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.6rem;
    flex-direction: column;
    @include for-tb() {
        width: 48%;
        margin-right: 4%;
        font-size: 2.2rem;
    }
    @include for-sp() {
        width: 100%;
        height: 80px;
        font-size: 1.8rem;
    }
    .btn-label {
        font-size: 3.6rem;
        @include for-tb() {
            font-size: 3rem;
        }
        @include for-sp() {
            font-size: 2.0rem;
        }
        &:before {
            width: 30px;
            height: 7px;
        }
    }
}

.cta-tel {
    width: 464px;
    height: 100px;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    pointer-events: none;
    @include for-tb() {
        width: 48%;
    }
    @include for-sp() {
        width: 100%;
        height: 80px;
        margin-top: 16px;
        pointer-events: auto;
    }
    &:after {
        background: linear-gradient(100deg, #333 0%, #333 70%, #964543);
    }
    a:hover {
        text-decoration: none;
    }
}

.cta-tel-label {
    display: block;
    font-size: 1.5rem;
    letter-spacing: 0.15em;
    position: relative;
    z-index: 2;
    @include for-sp() {
        font-size: 1.3rem;
    }
}

.cta-tel-label-accept {
    font-size: 1.3rem;
}

.cta-tel-number {
    display: block;
    margin-top: 7px;
    margin-bottom: 7px;
    @extend .prompt;
    font-size: 4rem;
    position: relative;
    z-index: 2;
    @include for-tb() {
        font-size: 3rem;
    }
    @include for-sp() {
        font-size: 2.8rem;
    }
}

.cta-contact-label {
    display: block;
    font-size: 1.5rem;
    letter-spacing: 0.15em;
    position: relative;
    z-index: 2;
    @include for-sp() {
        font-size: 1.3rem;
    }
}

.btn-contact-ja {
    font-size: 3rem;
}

.btn-contact-en {
    display: block;
    margin-top: 7px;
    //margin-bottom: 7px;
    font-size: 2rem;
    position: relative;
    z-index: 2;
}

// フッター
footer {
    background: #2b2b2b;
    padding: 100px 0 30px;
    position: relative;
    @include for-sp() {
        padding: 60px 0 20px;
    }
}

.foot-box {
    padding: 0 16vw;
    @media screen and (max-width: 1200px) {
        padding: 0 6vw;
    }
    @include for-sp() {
        padding: 0 10vw;
        display: flex;
        flex-direction: column-reverse;
    }
}

.foot-siteindex {
    display: flex;
    padding-bottom: 110px;
    @include for-sp() {
        padding-bottom: 60px;
        margin-top: 20px;
    }
}

.foot-siteindex-list {
    font-size: 1.5rem;
    font-weight: 700;
    margin-right: 6vw;
    line-height: 2;
    @include for-sp() {
        font-size: 1.3rem;
        font-weight: normal;
        margin-right: 30px;
    }
    li {
        &:before {
            content: "・";
            color: #c6c6c6;
        }
    }
    a {
        color: #c6c6c6;
    }
}

.foot-address {
    position: absolute;
    right: 4vw;
    bottom: 46px;
    text-align: right;
    @include for-sp() {
        position: static;
        text-align: left;
    }
}

.foot-logo {
    @include for-sp() {
        width: 100px;
        a {
            display: block;
        }
        img {
            @include max(w);
        }
    }
}

.foot-address-text {
    font-size: 1.3rem;
    color: #c6c6c6;
    margin-top: 40px;
    @include for-sp() {
        margin-top: 24px;
        font-size: 1.2rem;
    }
}

.foot-address-map {
    margin-top: 5px;
    .btn-map {
        display: inline-block;
        width: 128px;
        border-radius: 8px;
        border: 1px solid #c6c6c6;
        line-height: 1;
        @extend .prompt;
        font-size: 2rem;
        text-align: center;
        padding: 8px 0;
        color: #c6c6c6;
        transform: scale(0.5);
        transform-origin: right top;
        opacity: 0.8;
        transition: all $hover;
        @include for-sp() {
            transform-origin: left top;
        }
        &:hover {
            text-decoration: none;
            background: #c6c6c6;
            color: #282828;
        }
    }
}

.copyright {
    display: block;
    text-align: center;
    color: #c6c6c6;
    font-size: 1.3rem;
    font-weight: 300;
    font-family: "Noto Sans JP", sans-serif;
    @include for-sp() {
        font-size: 1rem;
    }
}

.copymark {
    font-family: Verdana, "Droid Sans";
}

.tel-number {
    color: #fff;
}

// 下層ページ共通
.page-title {
    position: relative;
    margin-bottom: 100px;
    img {
        height: 288px;
        width: 1200px;
        margin: 0 auto;
        position: relative;
        z-index: 2;
        overflow: hidden;
        margin-left: -100px;
        object-fit: cover;
        object-position: 50% 0%;
    }
    @media screen and (max-width: 1280px) {
        margin-bottom: -30px;
        img {
            width: 100%;
            height: auto;
            margin-left: auto;
            margin-right: auto;
        }
    }
    @include for-tb() {
        margin-bottom: 100px;
        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }
    @include for-sp() {
        img {
            height: 132px;
        }
    }
    /*.credit {
		text-align: right;
		width: 1100px;
		position: absolute;
		@media screen and (max-width: 1280px) {
			width: 100%;
		}
		@include for-tb() {
			padding-right: 10px;
		}
		@include for-sp() {
			font-size: 1.2rem;
		}
	}*/
}

.main {
    padding: 70px 0;
    text-align: center;
    @include for-tb() {
        padding: 0;
    }
}

.page-body p {
    margin-bottom: 30px;
    color: $BLACK;
    @include for-tb() {
        font-size: 1.4rem;
    }
}

.page-body ul {
    margin-bottom: 10px;
    color: $BLACK;
}

.page-body h2 {
    font-size: 2.4rem;
    line-height: 1.8;
    color: $BLACK;
    margin-bottom: 30px;
    font-weight: 500;
    @include for-tb() {
        font-size: 1.9rem;
    }
}

.page-body h3 {
    text-align: center;
    margin-bottom: 20px;
    color: $BLACK;
    font-weight: 500;
    &:hover {
        color: $BDC;
    }
}

.block {
    //max-width: 960px;
    max-width: 1000px;
    margin: 0 auto 80px;
    @media screen and (max-width: 1280px) {
        width: 100%;
        max-width: 100%;
        padding: 60px 8% 0;
        margin: 0 auto 60px;
    }
    @include for-tb() {
        padding-top: 0px;
        padding-left: 35px;
        padding-right: 35px;
    }
    @include for-sp() {
        //padding: 40px 8% 0;
        margin: 0 auto 40px;
    }
}

.breadcrumb {
    font-size: 1.3rem;
    line-height: 1;
    text-align: left;
    margin-left: -100px;
    @media screen and (max-width: 1280px) {
        margin-left: auto;
        margin-top: -50px;
    }
    @include for-tb() {
        display: none;
    }
}

.breadcrumb li {
    display: inline-block;
    color: $BLACK;
}

.breadcrumb li::before {
    content: "/";
    display: inline-block;
    margin: 0 5px 0 2px;
    font-size: 1.8rem;
    color: #888;
}

.breadcrumb li:first-child::before {
    content: none;
}

.breadcrumb a {
    text-decoration: none;
    color: #888;
    &:hover {
        color: $BLACK;
    }
}

.title-image {
    //width: 1200px;
    //height: 288px;
    position: relative;
    @include for-tb() {
        margin-right: calc(50% - 50vw);
        margin-left: calc(50% - 50vw);
    }
}

.title-text {
    position: absolute;
    //right: 33%;
    bottom: calc(10% - 120px);
    width: 100%;
    text-align: left;
    padding-left: 20px;
    left: -10%;
    @media screen and (max-width: 1280px) {
        left: 0;
    }
    @include for-tb() {
        bottom: calc(10% - 180px);
        padding-left: 15px;
        padding-right: 0px;
    }
    @include for-sp() {}
    h1 {
        display: inline-block;
        background: linear-gradient(100deg, #333 0%, #333 70%, #964543);
        padding: 13px 20px;
        line-height: 1;
        color: #fff;
        text-align: center;
        position: relative;
        overflow: hidden;
        top: -60px;
        @extend .prompt;
        padding: 13px 0;
        padding-right: 80px;
        padding-left: 50px;
        z-index: 3;
        height: 86px;
        left: 20px;
        @media screen and (max-width: 1280px) {
            font-size: 1.7rem;
        }
        @include for-tb() {
            width: auto;
            word-break: revert;
            background: none;
            color: $BLACK;
            text-align: left;
            padding-left: 0px;
            padding-right: 0px;
        }
        @include for-sp() {
            //width: 130px;
        }
        &:after {
            opacity: 0.93;
        }
        .title-text-en {
            font-size: 5.4rem;
            @include for-tb() {
                font-size: 3.6rem;
                line-height: 1.2;
            }
            @include for-sp() {
                //font-size: 3.6rem;
            }
        }
        .title-text-ja {
            font-size: 2.2rem;
            @include for-tb() {
                font-size: 1.5rem;
                display: block;
            }
            @include for-sp() {
                //font-size: 1.5rem;
            }
        }
    }
}

.cta-lower {
    padding: 0 0 145px;
    @include for-sp() {
        padding: 0;
    }
}

.paginate {
    @include for-tb() {
        width: auto;
    }
}

.video-container{
    video{
        width: 80%;
        max-width: 960px;
        height: auto;
        display: block;
        margin: 0 auto;
        @include for-sp(){
            width: 100%;
            max-width: 100%;
        }
    }
}
// home
#home {
    .info-text {
        margin-left: 20px;
    }
    /*.credit-box {
		width: 1200px;
		margin: 0 auto;
		position: relative;
		z-index: 2;
		@include for-tb() {
			width: 100%;
			font-size: 1.4rem;
		}
		@include for-sp() {
			font-size: 1rem;
		}
		.credit {
			position: absolute;
			//text-align: right;
			right: 0;
			bottom: 0;
			@media screen and (max-width: 1200px) {
				right: 5%;
			}
			@media screen and (max-width: 1160px) {
				right: 8%;
			}
			@media screen and (max-width: 1120px) {
				right: 12%;
			}
			@media screen and (max-width: 1080px) {
				right: 15%;
			}
			@media screen and (max-width: 1040px) {
				right: 18%;
			}
			@media screen and (max-width: 1000px) {
				right: 21%;
			}
			@include for-tb() {
				right: 0;
			}
		}
	}*/
    .top-seminar-image {
        p {
            position: absolute;
            right: 5%;
            bottom: 25%;
            z-index: 2;
            color: #fff;
            @media screen and (max-width: 1200px) {
                bottom: 33%;
            }
            @include for-tb() {
                bottom: 0;
                font-size: 1.4rem;
            }
            @include for-sp() {
                font-size: 1.2rem;
            }
        }
    }
}

// company
#company {
    body h2 {
        font-size: 2.4rem;
        padding-top: 22px;
    }
    .page-body h2 {
        font-size: 3.2rem;
        line-height: 1.2;
        color: $BLACK;
        margin-bottom: 30px;
    }
    .page-body h3 {
        text-align: left;
        font-size: 2.4rem;
        margin-bottom: 20px;
        @include for-sp() {
            font-size: 1.9rem;
        }
    }
    .company-tb {
        @include for-tb() {
            padding: 0;
            display: block;
            text-align: left;
            width: 100%;
        }
        table {
            width: 100%;
            margin-top: 20px;
            table-layout: fixed;
            @include for-tb() {
                font-size: 1.4rem;
            }
            @include for-sp() {
                //display: none;
            }
        }
        table tbody th {
            font-weight: unset;
            border: none;
            width: 20%;
            padding-left: 20px;
            @include for-sp() {
                padding-left: 0px;
                font-size: 1.2rem;
                white-space: nowrap;
            }
        }
        table tbody td {
            border: none;
            line-height: 1.8;
            @include for-sp() {
                padding-left: 0px;
                font-size: 1.4rem;
            }
            .company-tb-link {
                color: $RED;
            }
        }
        th,
        td {
            @include for-sp() {
                display: block;
                text-align: left;
                width: 100%;
            }
        }
        .company-tb-white {
            //white-space: nowrap;
        }
        .company-tb-gray {
            background: $BGC_GRAY;
            @include for-sp() {
                background: none;
            }
            //white-space: nowrap;
            a {
                color: $RED;
            }
        }
        .company-tb-border {
            border-bottom: 1px solid $BLACK;
        }
        .company-tb-height td {
            height: 125px;
        }
        /*.company-detail-sp{
			display: none;
			@include for-sp(){
				display: contents;
			}
		}*/
        .tb-tel {
            @include for-sp() {
                a:hover {
                    text-decoration-color: $RED;
                }
            }
        }
        .tel-number {
            color: $BLACK;
            @include for-sp() {
                color: $RED;
            }
        }
    }
    .gmap {
        @media screen and (max-width: 1280px) {
            padding: inherit;
        }
        @include for-tb() {
            padding: 0;
        }
        img {
            max-width: 500px;
            width: 100%;
            margin: 0 auto;
            border: 1px solid $BLACK;
            @include for-sp() {
                width: 100vw;
                border: none;
                border-top: 1px solid $BLACK;
                border-bottom: 1px solid $BLACK;
                margin-left: calc(((100vw - 100%) / 2) * -1);
                margin-right: calc(((100vw - 100%) / 2) * -1);
            }
        }
    }
    .gmap-text {
        display: flex;
        flex-wrap: wrap;
        gap: 10px 20px;
        padding-bottom: 80px;
        margin-top: 10px;
        @include for-tb() {
            display: block;
        }
        @include for-sp() {
            padding-bottom: 40px;
            margin-top: 20px;
        }
    }
    .gmap-text-list {
        width: calc((100% - 20px)/2);
        margin-bottom: 0;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 2;
        text-align: left;
        @include for-tb() {
            width: 100%;
        }
        @include for-sp() {
            font-size: 1.3rem;
            font-weight: normal;
        }
        li {
            list-style-type: none;
            font-weight: 500;
            @include for-sp() {
                font-size: 1.4rem;
            }
        }
        .station {
            font-size: 1.8rem;
            margin-bottom: 5px;
            margin-top: 20px;
            @include for-sp() {
                margin-bottom: 0;
                font-size: 1.6rem;
            }
        }
    }
    .print-btn-wrap {
        margin-bottom: 80px;
        @include for-sp() {
            margin-bottom: 60px;
        }
    }
    .print-btn {
        transition: 0.3s;
        &:hover {
            opacity: 0.7;
            transition: 0.3s;
        }
        img {
            width: 300px;
            border: none;
        }
    }
    .gmap-inner {
        @include for-sp() {
            width: 100vw;
            margin-left: calc(((100vw - 100%) / 2) * -1);
            margin-right: calc(((100vw - 100%) / 2) * -1);
        }
        iframe {
            width: 100%;
            border: 1px solid $BLACK;
            @include for-sp() {
                border: none;
                border-bottom: 1px solid $BLACK;
                border-top: 1px solid $BLACK;
                height: 375px;
            }
        }
        p {
            text-align: left;
            @include for-sp() {
                text-align: center;
            }
            a {
                color: $RED;
            }
        }
    }
}

// staff
#staff {
    .staff-title {
        text-align: left;
        @include for-sp() {
            text-align: center;
        }
    }
    .staff-president {
        @include for-tb() {
            padding-top: 60px;
        }
    }
    .staff-list {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 20px 95px;
        @media screen and (max-width: 1280px) {
            gap: 20px 10%;
        }
        @include for-tb() {
            //gap: 20px 50px;
        }
    }
    .president {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        @include for-sp() {
            display: block;
            justify-content: space-evenly;
            margin-left: calc((100vw - 100%) / 15);
            margin-right: calc((100vw - 100%) / 15);
        }
    }
    .consultant {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        @include for-sp() {
            display: block;
            justify-content: space-evenly;
            margin-left: calc((100vw - 100%) / 15);
            margin-right: calc((100vw - 100%) / 15);
        }
    }
    .advisor {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        @include for-sp() {
            display: block;
            justify-content: space-evenly;
            margin-left: calc((100vw - 100%) / 15);
            margin-right: calc((100vw - 100%) / 15);
        }
        .staff-image img {
            //visibility: hidden;
        }
    }
    .staff-box {
        width: 45%;
        @include for-sp() {
            width: auto;
            margin-bottom: 30px;
        }
        a {
            text-decoration: none;
            display: flex;
            justify-content: space-between;
            &:hover {
                opacity: 0.7;
                transition: opacity $hover;
                img {
                    opacity: 0.9;
                }
            }
        }
        &:after {
            content: "";
            width: 100%;
            height: 1px;
            background: #ea5550;
            position: absolute;
            right: 0%;
            bottom: 5%;
        }
    }
    .page-body h3:hover {
        color: $BLACK;
    }
    .staff-image {
        width: 150px;
        height: 187px;
        position: relative;
        z-index: 2;
        overflow: hidden;
        margin-bottom: 10px;
        @include for-tb() {
            width: 125px;
            height: 176px;
            margin-bottom: 0px;
        }
        @media screen and (max-width: 768px) {
            width: 100px;
            height: 151px;
            margin-bottom: -10px;
        }
        @include for-sp() {
            height: 152px;
        }
        img {
            width: 100%;
            height: auto;
            position: absolute;
            top: 0;
            left: 0;
            @include for-sp() {
                top: 3%;
            }
        }
    }
    .staff-contents {
        margin-top: 30px;
    }
    .staff-post {
        text-align: left;
        color: $BLACK;
        @include for-tb() {
            font-size: 1.2rem;
        }
    }
    .staff-name {
        text-align: left;
        font-size: 2.8rem;
        @include for-tb() {
            font-size: 2rem;
        }
        br {
            display: block;
            content: "";
            margin-top: -20px;
            @include for-tb() {
                margin-top: -10px;
            }
        }
    }
    .staff-box:nth-child(4n + 1) {
        margin-left: 0;
    }
    .staff-detail-block {
        @media screen and (max-width: 768px) {
            margin-bottom: 35px;
        }
        @include for-sp() {
            //margin-left: 0;
            //margin-right: 0;
        }
    }
    .staff-detail {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin-bottom: 80px;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        padding-top: 60px;
        @include for-tb() {
            justify-content: left;
        }
        @media screen and (max-width: 768px) {
            margin-bottom: 0px;
        }
        @include for-sp() {
            margin-right: 150px;
        }
    }
    .staff-detail-image img {
        width: 100%;
        height: auto;
    }
    .staff-detail-post {
        color: $BLACK;
        font-size: 1.8rem;
        padding-bottom: 20px;
        @include for-sp() {
            font-size: 1.4rem;
        }
    }
    .staff-detail-name {
        h1 {
            position: relative;
        }
        &:after {
            content: "";
            display: block;
            position: absolute;
            -webkit-transform: translate(0%);
            transform: translate(0%);
            width: 100vw;
            height: 1px;
            background-color: #e65754;
        }
    }
    .entry-title {
        font-size: 3.4rem;
        line-height: 1.2;
        color: $BLACK;
        padding-bottom: 30px;
        @include for-sp() {
            font-size: 2.1rem;
            padding-bottom: 10px;
        }
    }
    .staff-detail-roman {
        font-size: 1.6rem;
        @include for-tb() {
            font-size: 1.3rem;
        }
        @include for-sp() {
            font-size: 1.1rem;
        }
    }
    .staff-detail-contents {
        width: calc(100% - 360px);
        padding-top: 100px;
        -webkit-transition: right 1s cubic-bezier(0.1, 0.82, 0.1, 1) 1s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s;
        //transition: right 1s cubic-bezier(0.1, 0.82, 0.1, 1) 1s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s;
        transition: top 1.2s cubic-bezier(0.1, 0.82, 0.1, 1) 0.1s, opacity 0.4s linear 0.1s;
        text-align: left;
        @include for-tb() {
            margin-left: 40px;
        }
        @media screen and (max-width: 768px) {
            padding-top: 150px;
        }
        @include for-sp() {
            //margin-left: 0px;
            padding-top: 65px;
            white-space: nowrap;
            margin-left: 15px;
        }
    }
    .staff-detail-image {
        width: 300px;
        overflow: hidden;
        position: relative;
        z-index: 1;
        @include for-tb() {
            width: 240px;
        }
        @include for-sp() {
            width: 130px;
        }
    }
    .staff-detail-name {
        margin-bottom: 30px;
        font-weight: 500;
    }
    .staff-detail-nopic {
        display: -webkit-box;
        display: -ms-flexbox;
        margin-bottom: 80px;
        padding-top: 60px;
        .staff-detail-contents {
            padding-top: 0;
            @include for-tb() {
                margin-left: 0;
            }
        }
        .staff-detail-other {
            @media screen and (max-width: 768px) {
                display: initial;
                white-space: nowrap;
            }
            @include for-sp() {
                white-space: inherit;
            }
        }
    }
    .staff-detail-other {
        @media screen and (max-width: 768px) {
            display: none;
        }
    }
    .staff-detail-other-sp {
        display: none;
        @media screen and (max-width: 768px) {
            display: initial;
            text-align: left;
        }
        p {
            @include for-sp() {
                margin-top: 24px;
            }
        }
    }
    .staff-detail-body{
        a{
            color: $RED;
        }
    }
    .career-detail-title {
        @media screen and (max-width: 768px) {
            text-align: left;
        }
    }
    .career-detail {
        display: flex;
        @media screen and (max-width: 768px) {
            display: block;
            padding-right: 0;
        }
    }
    .career-title {
        display: inline-block;
        border-right: 1px solid #e65754;
        padding-right: 50px;
        text-align: left;
        white-space: nowrap;
        @include for-tb() {
            padding-right: 20px;
        }
        @media screen and (max-width: 768px) {
            border: none;
        }
    }
    .career-detail-tb {
        flex: 1;
        text-align: left;
        margin-left: 50px;
        @include for-tb() {
            margin-left: 20px;
        }
        @media screen and (max-width: 768px) {
            margin-left: 0px;
        }
        p {
            padding-top: 5px;
            white-space: nowrap;
        }
        dt {
            float: left;
            width: 120px;
            @include for-sp() {
                width: 70px;
            }
        }
        dd {
            white-space: nowrap;
            @include for-tb() {
                white-space: initial;
            }
        }
        .career-dd-pc {
            @include for-sp() {
                display: none;
            }
        }
        .career-dd-sp {
            display: none;
            @include for-sp() {
                display: block;
            }
        }
    }
    .career-previous p {
        margin-bottom: 20px;
        margin-top: 5px;
    }
    .career-tb {
        color: $BLACK;
        @media screen and (max-width: 768px) {
            font-size: 1.4rem;
        }
    }
    .career-tb-br {
        @include for-tb() {
            display: none;
        }
    }
    .position-list {
        @media screen and (max-width: 768px) {
            font-size: 1.4rem;
        }
        .position-list-pc {
            @include for-sp() {
                display: none;
            }
        }
        .position-list-sp {
            display: none;
            @include for-sp() {
                display: block;
            }
        }
    }
    .book-list {
        @media screen and (max-width: 768px) {
            font-size: 1.4rem;
        }
    }
    .career-detail-gray {
        background-color: $BGC_GRAY;
        text-align: left;
        margin-top: 10px;
        padding-top: 50px;
        height: auto;
        padding-bottom: 50px;
    }
    .staff-link {
        text-align: right;
        margin-top: 20px;
        @include for-sp() {
            text-align: center;
        }
        a {
            color: #e65754;
        }
    }
}

// seminar
#seminar {
    .page-body h3 {
        font-size: 1.8rem;
        text-align: left;
        @include for-sp() {
            font-size: 1.4rem;
        }
    }
    .page-body p {
        margin-bottom: 0;
    }
    .title-text {
        width: 110%;
    }
    .title-text h1 {
        //left: 27%;
        @include for-tb() {
            //left: 0;
        }
    }
    .title-text-en {
        @include for-sp() {
            line-height: 0;
        }
    }
    .title-text-en-pub {
        @include for-sp() {
            display: block;
            line-height: 0.9;
        }
    }
    .seminar-top-tub {
        padding-top: 10px;
    }
    .seminar-top-tub-list {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-top: 20px;
        //justify-content: space-between;
        justify-content: space-evenly;
        @include for-sp() {
            justify-content: space-between;
        }
    }
    .seminar-top-tub-list li {
        margin-top: 15px;
        line-height: 1;
        width: 32.5%;
        @include for-sp() {
            width: 30%;
        }
    }
    .seminar-top-tub-list .btn {
        width: 100%;
        padding: 17px 0;
        font-size: 2.2rem;
    }
    .btn-label-seminar {
        display: inline-block;
        position: relative;
        z-index: 3;
        @include for-sp() {
            font-size: 1.6rem;
        }
        &:before {
            content: "";
            width: 23px;
            height: 5px;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            transition: right 0.2s ease;
        }
    }
    .seminar-title {
        text-align: left;
    }
    .seminar-schedule {
        @include for-sp() {
            padding-bottom: 70px;
        }
        &:after {
            content: "";
            width: 1000vh;
            height: 1px;
            background: #ea5550;
            position: absolute;
            right: 55%;
            bottom: 0;
            @include for-sp() {
                content: none;
            }
        }
    }
    .seminar-box {
        text-align: left;
        margin-bottom: 50px;
        font-size: 1.6rem;
        position: relative;
        dt {
            float: left;
            padding-right: 30px;
            width: 25%;
            @include for-sp() {
                display: contents;
            }
        }
        dd {
            @include for-sp() {
                width: auto;
            }
        }
    }
    .seminar-box-title {
        //margin-top: 30px;
        p {
            margin-bottom: 10px;
            margin-top: 5px;
            font-size: 1.9rem;
            display: block;
            //padding-top: 70px;
            //margin-top: -70px;
            @include for-sp() {
                line-height: 1.3;
                font-size: 1.6rem;
            }
        }
        a {
            font-size: 1.9rem;
            color: #e65754;
            //text-decoration: none;
            @include for-sp() {
                line-height: 1.3;
                font-size: 1.6rem;
            }
        }
    }
    .seminar-detail-tb {
        @include for-sp() {
            position: relative;
        }
        &:before {
            content: "";
            width: 100%;
            height: 1px;
            background: $BLACK;
            position: absolute;
        }
    }
    .seminar-tb {
        padding-top: 10px;
        @include for-sp() {
            background-color: $BGC_GRAY;
            padding-left: 10px;
            padding-right: 10px;
        }
        a {
            color: $RED;
        }
        dt {
            @include for-sp() {
                padding-bottom: 10px;
                font-size: 1.2rem;
            }
        }
        dd {
            @include for-sp() {
                padding-bottom: 10px;
                font-size: 1.4rem;
                line-height: 1.2;
            }
            ul{
                display: grid;
                @include for-sp(){
                    gap: 10px;
                }
            }
        }
        .tel-number {
            color: $BLACK;
            @include for-sp() {
                color: $RED;
            }
        }
    }
    .seminar-btn {
        text-align: right;
        padding-bottom: 50px;
        @include for-sp() {
            text-align: center;
            padding-bottom: 0;
        }
    }
    .seminar-publish-table {
        margin-bottom: 50px;
        thead {
            @include for-sp() {
                display: none;
            }
        }
        tbody {
            font-size: 1.5rem;
            @include for-sp() {
                font-size: 1.2rem;
            }
            tr:nth-child(2n) {
                background-color: $BGC_GRAY;
            }
        }
    }
    .seminar-publish-table-head {
        td {
            border-top: none;
            border-bottom: 2px solid $BLACK;
            @include for-sp() {
                font-size: 1.2rem;
            }
        }
    }
    .seminar-publish-table-title {
        width: auto;
    }
    .seminar-publish-title {
        @include for-sp() {
            font-size: 1.6rem;
            padding-bottom: 12px;
        }
    }
    .seminar-publish-magazin {
        width: 350px;
        @include for-tb() {
            width: auto;
        }
    }
    .seminar-publish-publisher {
        width: 170px;
        @include for-sp() {}
    }
    .seminar-publish-writer {
        width: 100px;
        @include for-sp() {}
    }
    .seminar-publish-table-left {
        border-left: none;
        a {
            color: $FONT_COLOR;
            //position: relative;
            &:hover {
                //text-decoration: none;
                color: $RED;
            }
            &:before{
                //position: absolute;
                content: "PDF";
                background: $RED;
                color: #fff;
                display: inline-block;
                padding: 1px 5px;
                border-radius: 4px;
                font-size: 1.2rem;
                margin-right: 5px;
                letter-spacing: 0.1em;
            }
        }
    }
    .seminar-publish-table-right {
        border-right: none;
    }
    .seminar-publish {
        @include for-sp() {
            padding-bottom: 75px;
        }
        &:after {
            content: "";
            width: 1000vh;
            height: 1px;
            background: #ea5550;
            position: absolute;
            bottom: 0;
            left: 60%;
            @include for-sp() {
                content: none;
            }
        }
    }
    .seminar-book-list {
        display: flex;
        width: 100%;
        margin-bottom: 30px;
        flex-wrap: wrap;
    }
    .seminar-book-detail {
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        align-items: center;
        width: 48%;
        justify-content: space-between;
        margin-bottom: 60px;
        margin-left: 4%;
        @include for-sp() {
            width: auto;
            margin-left: 0;
            margin-bottom: 15px;
        }
        &:nth-child(2n + 1) {
            margin-left: 0;
        }
    }
    .seminar-book-detail-image {
        width: 130px;
        @include for-sp() {
            width: 90px;
        }
        a {
            display: block;
        }
    }
    .seminar-book-detail-contents {
        width: calc(100% - 150px);
        -webkit-transition: right 1s cubic-bezier(0.1, 0.82, 0.1, 1) 1s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s;
        transition: right 1s cubic-bezier(0.1, 0.82, 0.1, 1) 1s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s;
        text-align: left;
        @include for-sp() {
            margin-right: 10%;
        }
    }
    h3.seminar-book-detail-post:hover {
        color: $BLACK;
    }
    .seminar-book-detail-post {
        padding-top: 10px;
        font-size: 1.8rem;
        margin-bottom: 15px;
    }
    .seminar-book-detail-image img {
        width: 100%;
        height: auto;
    }
    .seminar-book-detail-other {
        font-size: 1.6rem;
        line-height: 1.5;
        @include for-sp() {
            font-size: 1.2rem;
        }
    }
    .seminar-book {
        @include for-sp() {
            margin-bottom: 80px;
        }
        /*&:after {
			content: "";
			width: 1000vh;
			height: 1px;
			background: #ea5550;
			position: absolute;
			bottom: 0;
			left: 60%;
		}*/
    }
    .btn-seminar {
        width: 220px;
    }
    .seminar-link {
        text-align: right;
        margin-top: 20px;
        @include for-sp() {
            text-align: center;
        }
        a {
            color: $RED;
        }
    }
    .active {
        &:after {
            background: $RED;
        }
    }
}

// privacy
#privacy {
    .title-text-privacy {
        font-size: 4.2rem;
        color: $BLACK;
        text-align: center;
        position: static;
    }
    .title-text-ja {
        font-size: 3.2rem;
        @include for-sp() {
            font-size: 2.4rem;
        }
    }
    .page-body p {
        text-align: left;
        @include for-sp() {
            margin-bottom: 20px;
        }
    }
    .page-body ul {
        margin-bottom: 30px;
        text-align: left;
    }
    .page-body h2 {
        @include for-sp() {
            padding-top: 20px;
            margin-bottom: 20px;
            font-size: 1.7rem;
        }
    }
    body h2 {
        font-size: 2.4rem;
        padding-top: 22px;
    }
    .primary-num {
        font-family: "Roboto";
    }
    .page-body h2 {
        text-align: left;
        padding-top: 30px;
    }
    .box {
        border: 3px double #ea5550;
        padding: 30px;
        margin-bottom: 30px;
        line-height: 1.7;
    }
    .box h2,
    .box h3,
    .box h4,
    .box h5 {
        font-size: 2.4rem;
        margin-bottom: 15px;
        padding-top: 0;
        text-align: left;
        @include for-sp() {
            font-size: 2.0rem;
        }
    }
    .box h3:hover {
        color: $BLACK;
    }
    .gnavi-list li:nth-child(5) a::before {
        background: #ea5550;
    }
    .page-title {
        @media screen and (max-width: 1280px) {
            //font-size: 1.7rem;
        }
        @include for-tb() {
            height: auto;
            width: auto;
            margin-bottom: auto;
        }
        @include for-sp() {}
    }
}

// privacy
#contact {
    .form-body {
        @include for-tb() {
            padding-left: 0px;
            padding-right: 0px;
        }
    }
    .page-body p {
        text-align: left;
        @include for-sp() {
            font-size: 1.3rem;
        }
    }
    .page-body h2 {
        text-align: left;
    }
    .page-title {
        @include for-tb() {
            margin-bottom: 120px;
        }
    }
    .title-text h1 {
        //left: 7%;
    }
    .title-text-contact {
        .title-text-ja {
            font-size: 3.2rem;
        }
    }
    table {
        font-size: 1.5rem;
        line-height: 1.7;
        td,
        th {
            border: none;
            padding: 40px 0;
            border-bottom: 1px solid #3c4559;
            vertical-align: top;
            @include for-sp() {
                padding: 0;
                border-bottom: none;
            }
        }
        th {
            font-weight: 700;
            width: 240px;
            @include for-tb() {
                width: 190px;
            }
        }
        tr {
            @include for-sp() {
                padding: 20px 0;
            }
        }
        .require {
            font-size: 1.2rem;
            font-weight: 400;
            color: #fff;
            background: #d82f3f;
            line-height: 1;
            padding: 3px 5px;
            float: right;
            margin: 5px 25px 0 0;
            @include for-tb() {
                font-size: 1rem;
                padding: 2px 3px;
            }
        }
        .input-box {
            display: inline-block;
        }
        .form-name {
            width: 120px;
        }
        .form-comment {
            font-size: 1.3rem;
            line-height: 1.5;
            color: #77838f;
            display: block;
            padding-top: 5px;
            @include for-sp() {
                font-size: 1rem;
                padding: 2px 3px;
            }
        }
    }
    input[type="text"],
    input[type="number"],
    input[type="password"],
    input[type="email"],
    textarea {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 100%;
        border: 1px solid #d1d1d1;
        padding: 5px 7px;
        border-radius: 1px;
        font-size: 1.5rem;
        background: 0 0;
    }
    .contact-tb {
        #sei,
        #sei2,
        #seikana,
        #seikana2 {
            margin-right: 15px;
        }
        #zip1,
        #zip2 {
            margin: 0 3px 15px;
            width: 60px;
        }
        #fax,
        #tel,
        #tel2,
        #tel3 {
            width: 180px;
        }
        #ckemail,
        #ckemail2 {
            margin-top: 15px;
        }
        #comment {
            height: 150px;
            width: 100%;
        }
        .chkrequired::placeholder {
            font-size: 1.3rem;
        }
        #corpname,
        #department,
        #corpname-title,
        #email,
        #ckemail {
            width: 75%;
        }
        #address {
            margin-right: 10px;
        }
    }
    .btn-area {
        display: block;
        p {
            text-align: center;
        }
        a {
            color: $RED;
        }
    }
    .confirm-btn {
        @include for-sp() {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
        }
        #form-submit {
            @include for-tb() {
                width: 240px;
                letter-spacing: 0.05em;
            }
            @include for-sp() {
                width: 80%;
                margin-bottom: 30px;
            }
        }
    }
    #form-submit {
        display: inline-block;
        font-size: 2.1rem;
        color: #fff;
        width: 425px;
        padding: 25px 0;
        line-height: 1;
        background: $RED;
        -webkit-transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        letter-spacing: 0.2em;
        text-indent: 0.2em;
        border: none;
        border-radius: 8px;
        @include for-sp() {
            width: 90%;
        }
        &:hover {
            cursor: pointer;
        }
    }
    #form-back {
        display: inline-block;
        font-size: 1.6rem;
        color: #fff;
        padding: 14px 20px;
        line-height: 1;
        background: #888;
        -webkit-transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        border: none;
        border-radius: 8px;
        margin-right: 20px;
        @include for-sp() {
            margin-right: 0px;
            width: 80%;
        }
        &:hover {
            cursor: pointer;
        }
    }
    .thanks-box {
        h2 {
            padding-bottom: 50px;
            text-align: center;
            @include for-sp() {
                padding-bottom: 0;
            }
        }
        p {
            text-align: center;
        }
        a {
            color: $RED;
        }
    }
    .tbdisp {
        display: none;
        @include for-sp() {
            display: block;
        }
    }
    .err {
        width: fit-content;
    }
}

// service
#service {
    .title-image p {
        position: absolute;
        right: -11%;
        bottom: -10%;
        z-index: 2;
        color: #fff;
        @media screen and (max-width: 1280px) {
            right: 3%;
        }
        @include for-tb() {
            font-size: 1.4rem;
        }
        @include for-sp() {
            font-size: 1.2rem;
            bottom: -20%;
        }
    }
    .title-text h1 {
        //left: 5%;
        @include for-tb() {
            //left: 0;
        }
    }
    .service-detail {
        text-align: left;
        @include for-tb() {
            padding-top: 20px;
        }
        @include for-sp() {
            padding-top: 10px;
        }
        h2 {
            margin-bottom: 20px;
            &:after {
                content: "";
                display: block;
                position: absolute;
                -webkit-transform: translate(0);
                transform: translate(0);
                width: 100%;
                height: 1px;
                background-color: #e65754;
            }
        }
        p {
            margin-bottom: 50px;
            @include for-sp() {
                margin-bottom: 30px;
            }
        }
        .ma-span {
            margin-bottom: 35px;
            @include for-tb() {
                margin-bottom: 20px;
            }
            @include for-sp() {
                margin-bottom: 10px;
            }
        }
    }
}

// news
#news {
    .title-text h1 {
        //left: 0;
    }
    .page-body ul {
        text-align: left;
    }
    .info-list {
        @media screen and (max-width: 1280px) {
            margin-left: 50px;
        }
        @include for-tb() {
            padding-top: 20px;
            margin-left: auto;
        }
        li {
            //margin-bottom: 20px;
            margin-bottom: 10px;
        }
    }
    .info-text a {
        color: $BLACK;
    }
    span.info-date {
        margin-right: 20px;
    }
    .entry-title {
        font-size: 3.2rem;
        line-height: 1.2;
        color: #2a2a2f;
        margin-bottom: 10px;
        border-bottom: 1px solid $RED;
        padding-bottom: 20px;
        text-align: left;
    }
    .entry-date {
        text-align: left;
    }
    .date-wrap {
        margin-bottom: 40px;
        //position: relative;
    }
    .entry-body {
        margin-top: 30px;
        padding-bottom: 50px;
        text-align: left;
    }
    .entry-navi {
        text-align: right;
    }
    .entry-back {
        margin-bottom: 30px;
        a {
            color: $RED;
        }
    }
    .entry-prev {
        text-align: left;
        a {
            color: $RED;
        }
    }
    .entry-next {
        text-align: right;
        margin-top: -30px;
        a {
            color: $RED;
        }
    }
}

// sitemap
#sitemap {
    .page-title {
        @include for-tb() {
            margin-bottom: 50px;
        }
    }
    .title-text-privacy {
        font-size: 4.2rem;
        color: $BLACK;
        text-align: center;
        position: static;
    }
    .title-text-ja {
        font-size: 3.2rem;
    }
    .sitemap-list {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        text-align: left;
        margin-left: 100px;
        @include for-tb() {
            margin-left: 50px;
            margin-bottom: 70px;
        }
        @include for-sp() {
            margin-left: auto;
        }
        li:first-child {
            padding-top: 0 !important;
        }
        li {
            padding-left: 0;
        }
        a {
            color: $BLACK;
            &:hover {
                color: $RED;
            }
        }
    }
    .sitemap-first {
        width: 100%;
        padding-top: 30px;
        font-weight: 700;
    }
    .sitemap-semiar {
        padding-bottom: 10px;
    }
    .sitemap-second {
        width: 33.33%;
        font-size: 1.3rem;
        //margin-right: -150px;
        //margin-left: 12px;
        @include for-tb() {
            width: 49%;
        }
        @include for-sp() {
            font-size: 1.2rem;
        }
    }
    .sitemap-span1 a {
        padding-left: 12px;
    }
    .sitemap-span3 a {
        @include for-tb() {
            padding-left: 12px;
        }
    }
}