//module
a{text-decoration: none;transition: opacity $hover;
	&:hover{text-decoration: underline;
		img{opacity:0.7; transition: opacity $hover;}
	}
}

.tbdisp{display: none;}
.spdisp{display: none;}

@include for-tb(){
	.tbdisp{display: block;}
	.tbhide{display: none;}
}

@include for-sp(){
	.spdisp{display: block;}
	.sphide{display: none;}
}


// clearfix
.cf:after{content:"";display:block;	clear:both;}

.center {	text-align: center;}
.right {	text-align: right;}
.left {	text-align: left;}
.fl-left {	float: left;}
.fl-right {	float: right;}
.cl{clear:both;}

.btn{
	display: inline-block;border-radius: 8px;padding: 13px 20px;line-height: 1;
	color: #fff;text-align: center;	
	position: relative;overflow: hidden;
	&:before,&:after{
		content:"";width: 100%;height: 100%;
	}
	&:after{
		background: $RED;
		position: absolute;left:0;top:0;z-index: 1;
		transition:opacity $hover;
	}
	&:before{
		background: rgba(#fff,0.15);
		position: absolute;left:0;top:0;z-index: 2;opacity: 0;
		transition:opacity $hover;
	}
	&:hover{
		text-decoration: none;
		text-shadow: 0 0 6px rgba(#fff,0.2);
		&:before{
			opacity: 1;
		}
		.btn-label:before{
			right:-7px;
		}
	}
	.btn-label{
		display: inline-block;position: relative;z-index: 3;
		padding-right: 45px;
		&:before{
			content:"";width: 23px;height: 5px;
			background: url(../images/icon_btn_arrow.png) 0 0 no-repeat;background-size:cover;
			position: absolute;right:0;top:50%;transform: translateY(-50%);
			transition:right 0.2s ease;
		}
	}
}

.btn-grad{
	&:after{
		background: linear-gradient(100deg,#333 0%,#333 60%,#B94D49);
	}
	
	&:hover{
		
	}
}


.btn-area{
	display: flex;justify-content: center;margin-top: 40px;
}

.arrow{

}

//フォントサイズ
.l{font-size:1.8rem;display: inline-block;}
.s{font-size:1.4rem;display: inline-block;}
.xs{font-size:1.2rem;display: inline-block;}

@include for-sp(){
	.xs{font-size: 1.0rem;}
	.s{font-size: 1.2rem;}
	.l{font-size: 1.6rem;}
}


//フォントカラー
.red{	color: $RED;}





//表組み
table{
	width: 100%;font-size: $FONT_SIZE;line-height: 1.5;
	th,td{padding:16px 10px;border: 1px solid $BDC;}
	thead th{	font-weight: bold; text-align: center;}
	tbody th{ font-weight: bold;}
}

@include for-sp(){
	table{
		font-size: 1.4rem;
		&:not(.nobreak){
			tr,th,td{display: block;border: none;padding: 0;width: 100%;}
			tr{border-bottom: 1px solid $BDC;padding: 16px 0;}
			td{margin-top: 5px;}
		}
	}
}


//罫線
hr{	border: none;border-bottom: 1px solid $BDC;padding-top:80px;margin-bottom: 80px;}


//ページネーション
.paginate{padding-top: 64px;width: 960px;margin: 0 auto;	}

.paginate-list{
	display: flex;justify-content: center;position: relative;
	li{
		width: 36px;padding-left: 0;margin: 0 10px;
		&:before{display: none;}
	}
	.number{
		display: block;font-size: 1.6rem;text-align: center;line-height: 36px;height: 36px;letter-spacing: 0;
		color: $FONT_COLOR;border: 1px solid $BLACK;
	}
	a.number{transition:background 0.3s $ease5;}
	a.number:hover{
		background: $BGC_GRAY;text-decoration: none;
		color: $RED;
		border: 1px solid $RED;
	}
	.disabled{color: $BDC;
		.number{color: $BDC;}
	}
	.current{
		.number{background: $BGC_GRAY;pointer-events: none;}
	}
	.list{width: auto;}
	.prev,.next{
		position: absolute;top:0;color: $GRAY;
		.number{font-size: 2rem;border: none;}
	}
	.prev{left:0;}
	.next{right:0;}
	a.prev{
		color: $BLACK;
		&:hover{
			color: $RED;
			text-decoration: none;
		}
	}
	a.next{
		color: $BLACK;
		&:hover{
			color: $RED;
			text-decoration: none;
		}
	}
}
@include for-sp(){
	.paginate{
		width: 100%;padding: 30px 4% 0;
	}
}
@include for-sp(){
	.paginate-list{
		li{width: 30px;margin:	0 2px;}
		.number{
			font-size: 1.3rem;height: 	30px;line-height: 30px;
		}
	}
}




.anm{
	position: relative;top:50px;opacity: 0;z-index: 2;transition:top 1.2s $ease9 0.1s,opacity 0.4s linear 0.1s;
	&.is-show{
		top:0;opacity: 1;
	}
}