//base


*,:before,:after{box-sizing:border-box; word-break: break-all;}

html{
	font-size: 62.5%;
}
img{
	vertical-align: middle;
}
ol{
	list-style-position: inside;
}

strong{	font-weight: bold;color: $BLACK;}
sup,sub{height: 0; line-height: 1; vertical-align: baseline;  _vertical-align: bottom; position: relative; font-size:1rem;}
sup{bottom: 1ex;}
sub{top: .5ex;}

body{
	font-size:$FONT_SIZE;font-family:$FONT_FAMILY; color:$FONT_COLOR;line-height: $LINE_HEIGHT;letter-spacing: 0.12em;
	font-feature-settings: "palt";	-webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;
	-webkit-text-size-adjust: 100%;font-weight: 500;
}

@include for-sp(){
	body{
		font-size: 1.4rem;
	}
}


::selection {
	background: $GRAY;
	color: #fff;
}

.prompt{
	font-family: 'Prompt', sans-serif;	
}
.textjustify{
	width: max-content;
	text-align-last: justify;
	@media (max-width: 350px) {
		width: 100%;
		text-align-last: left;
	}
}