//print
body{
	zoom: 0.65;
}
#wrapper{
	min-width:0;
}

.anm{
	opacity: 1;top:0;
}

// 改行の調整
#staff .career-tb-br {
	display: block!important;
}
