//トップページ
.top-tit {
    color: $BLACK;
    line-height: 1;
    &-en {
        @extend .prompt;
        font-size: 4.4rem;
        display: block;
        letter-spacing: 0.02em;
        @include for-sp() {
            font-size: 3.2rem;
        }
    }
    &-jp {
        font-size: 1.4rem;
        display: block;
        margin-top: 13px;
        @include for-sp() {
            font-size: 1.3rem;
            margin-top: 9px;
        }
    }
}

.key {
    padding-top: 80px;
    position: relative;
    z-index: 2;
    @include for-tb() {
        padding-top: 20px;
    }
    @include for-sp() {
        padding-top: 0;
    }
}

.key-slide {
    height: 448px;
    width: 1200px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    overflow: hidden;
    @media screen and (max-width: 1280px) {
        width: 94vw;
    }
    @include for-tb() {
        width: 100vw;
    }
    @include for-sp() {
        height: 75vw;
    }
}

.key-slide-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    z-index: 1;
    white-space: nowrap;
    .key-tit {
        color: #fff;
        text-align: center;
        line-height: 1;
        @include for-tb() {
            line-height: 1.3;
        }
    }
    .key-tit-jp {
        display: block;
        font-size: 3.6rem;
        font-weight: 700;
        text-shadow: 0 6px 6px rgba(0, 0, 0, 0.4);
        @include for-sp() {
            font-size: 2.4rem;
        }
        >span {
            font-weight: 300;
        }
    }
    .key-tit-en {
        display: block;
        margin-top: 24px;
        @extend .prompt;
        font-size: 1.8rem;
        @include for-sp() {
            font-size: 1.3rem;
        }
    }
}

.swiper-pagination {
    position: relative;
    z-index: 2;
    bottom: 0;
    margin-top: 10px;
}

.swiper-pagination-bullet {
    width: 20px;
    height: 10px;
    background: none;
    position: relative;
    opacity: 1;
    margin: 0 5px;
    &:before {
        content: "";
        width: 100%;
        height: 2px;
        border-radius: 1px;
        position: absolute;
        top: 4px;
        left: 0;
        background: #707070;
        transition: background $hover;
    }
    &:hover {
        &:before {
            background: $RED;
        }
    }
    &.swiper-pagination-bullet-active {
        &:before {
            background: $RED;
        }
    }
}

.key-bg {
    width: 540px;
    height: calc(100% + 140px);
    background: $RED;
    position: absolute;
    top: -140px;
    right: 0;
    z-index: 1;
    transform: skew(-10deg);
    transform-origin: right bottom;
    @media screen and (max-width: 1200px) {
        width: 440px;
        height: calc(100% + 100px);
        top: -100px;
    }
    @include for-tb() {
        width: 40vw;
    }
    @include for-sp() {
        width: 50vw;
    }
    .key-bg-svg {
        width: 110%;
        height: auto;
        position: absolute;
        bottom: 0;
        left: 5%;
        transform: skew(10deg);
        @include for-tb() {
            width: 130%;
        }
        @include for-sp() {
            width: 110%;
        }
    }
}

.key1 {
    background: url(../../images/key5.jpg) center center no-repeat;
    background-size: cover;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /*.key-tit{
		color: #fff;text-align: center;line-height: 1;
		@include for-tb(){
			line-height: 1.3;
		}
	}
	.key-tit-jp{
		display: block;font-size: 3.6rem;font-weight: 700;	
		text-shadow: 0 6px 6px rgba(0,0,0,0.4);
		@include for-sp(){
			font-size: 2.4rem;
		}
		>span{
			font-weight: 300;
		}
		
	}
	.key-tit-en{
		display: block;margin-top: 24px;
		@extend .prompt;font-size: 1.8rem;
		@include for-sp(){
			font-size: 1.3rem;
		}
	}*/
}

.key2 {
    background: url(../../images/key6.jpg) center center no-repeat;
    background-size: cover;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.key3 {
    background: url(../../images/key7.jpg) center center no-repeat;
    background-size: cover;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .swiper-caption{
        //display: none;
    }    
}

.key4 {
    background: url(../../images/key8.jpg) center center no-repeat;
    background-size: cover;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.swiper-caption {
    position: relative;
    color: #fff;
    left: 40%;
    top: 45%;
    opacity: 0.6;
    font-size: 1.2rem;
    letter-spacing: 0.05em;
    @include for-tb() {
        left: 37%;
    }
    @include for-sp() {
        font-size: 1rem;
        left: 28%;
    }
}

.top-news-main {
    max-width: 1000px;
    margin: 0 auto;
    z-index: 3;
    position: relative;
    @include for-tb(){
        max-width: 84vw;
    }
    @include for-sp() {
        width: 80%;
        padding: 0 0 40px;
    }
    a {
        display: block;
        position: relative;
        color: #3e3a39;
        font-size: 24px;
        @include for-sp() {
            font-size: 17px;
            padding-top: 15px;
        }
        &::before {
            // content: '';
            position: absolute;
            top: 17px;
            left: 0;
            width: 12px;
            height: 12px;
            transform: rotate(45deg);
            background: #3e3a39;
            @include for-sp() {
                top: 12px;
                width: 10px;
                height: 10px;
            }
        }
    }
    .top-news-list{
        li{
            padding: 22px 0;
            border-bottom: 1px solid #ea5550;
            display: flex;
            align-items: flex-start;
            gap: 20px;
            @include for-sp(){
                display: block;
            }
        }
        .border-bottom-none{
            border-bottom: none;
        }
    }
}

.top-news-main-tit {
    padding: 9px 10px;
    font-family: Prompt, sans-serif;
    font-weight: 500;
    font-size: 20px;
    white-space: nowrap;
    line-height: 1;
    background-color: #ea5550;
    color: #fff;
    margin-top: 5px;
    @include for-sp() {
        font-size: 16px;
        padding: 6px 8px;
    }
}

.top-news {
    width: 1000px;
    margin: 0 auto;
    padding: 75px 0 104px;
    position: relative;
    z-index: 3;
    @include for-tb() {
        width: 84vw;
    }
    @include for-sp() {
        width: 80vw;
        padding: 30px 0 50px;
    }
}

.top-news-tit {
    position: relative;
    &:before {
        content: "";
        width: 854px;
        height: 1px;
        background: $RED;
        position: absolute;
        left: 146px;
        top: 32px;
        @media screen and (max-width: 1560px) {
            width: 640px;
        }
        @include for-tb() {
            width: 40vw;
        }
        @include for-sp() {
            left: 108px;
            width: 90px;
        }
    }
}

.anm {
    .top-news-tit:before {
        transform: scaleX(0);
        transform-origin: right center;
        transition: transform 0.5s $ease7 0.5s;
    }
    &.is-show {
        .top-news-tit:before {
            transform: scaleX(1);
        }
    }
}

.top-news-list {
    margin-top: 32px;
    @include for-sp() {
        margin-top: 24px;
    }
    li {
        margin-bottom: 10px;
        @include for-sp() {
            margin-top: 5px;
        }
    }
}

.top-news-date {
    letter-spacing: 0.05em;
    display: inline-block;
    margin-right: 12px;
    @include for-sp() {
        font-size: 1.2rem;
        display: block;
        line-height: 1;
    }
}

.top-news-text {
    a {
        color: $FONT_COLOR;
    }
}

.info-text a {
    color: $BLACK;
}

.top-about {
    position: relative;
    padding: 16px 0 100px;
    @media screen and (max-width: 1360px) {
        padding-top: 70px;
    }
    @include for-sp() {
        padding: 40px 0 50px;
    }
    &:before {
        content: "";
        width: 46.7vw;
        height: 1px;
        background: $RED;
        position: absolute;
        left: 0;
        top: 0;
        @include for-tb() {
            width: 40vw;
        }
    }
}

.top-about.anm {
    &:before {
        transform: scaleX(0);
        transform-origin: left center;
        transition: transform 0.5s $ease7 0.5s;
    }
    &.is-show {
        &:before {
            transform: scaleX(1);
        }
    }
}

.top-about-box {
    width: 1000px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    @include for-tb() {
        width: 84vw;
        display: block;
    }
}

.top-about-image {
    width: 584px;
    margin-left: -146px;
    position: relative;
    margin-top: 35px;
    @media screen and (max-width: 1200px) {
        margin-left: calc((1000px - 100vw) / 2);
        width: 520px;
        img {
            @include max(w);
        }
    }
    @include for-tb() {
        width: 80%;
        margin-left: 0;
    }
    @include for-sp() {
        width: 100%;
    }
}

.top-about-btn {
    position: absolute;
    top: 223px;
    right: -10px;
    @media screen and (max-width: 1200px) {
        top: 190px;
    }
    @include for-tb() {
        top: auto;
        bottom: 30px;
    }
    .btn {
        @extend .prompt;
        width: 200px;
        height: 46px;
        font-size: 2rem;
        @include for-sp() {
            width: 180px;
            font-size: 1.7rem;
        }
        &:after {
            opacity: 0.93;
        }
    }
}

.top-about-contents {
    width: 524px;
    margin-right: -44px;
    @media screen and (max-width: 1200px) {
        width: 480px;
        margin-right: 0;
    }
    @include for-tb() {
        width: 100%;
    }
}

.top-about-text {
    text-align: justify;
    margin-top: 40px;
    @include for-sp() {
        margin-top: 24px;
    }
}

.top-middle {
    position: relative;
    &:before {
        content: "";
        width: 400px;
        height: 100%;
        background: $RED;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        transform: skew(10deg);
        transform-origin: left bottom;
        @include for-tb() {
            width: 40vw;
        }
        @include for-sp() {
            width: 50vw;
        }
    }
}

.top-service {
    background: $BGC_GRAY;
    padding: 75px 0 90px;
    @include for-sp() {
        padding: 40px 0 50px;
    }
}

.top-service-box {
    width: 1000px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    @include for-tb() {
        width: 84vw;
    }
    @include for-sp() {
        width: 80vw;
    }
}

.top-service-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    justify-content: center;
    @include for-sp() {
        justify-content: space-between;
    }
    li {
        //width: calc((100% - 26px) / 3);
        width: 40%;
        margin-top: 15px;
        line-height: 1;
        margin-left: 13px;
        @include for-sp() {
            width: 48%;
            margin-left: 0 !important;
            margin-top: 10px;
        }
        &:nth-child(3n + 1) {
            //margin-left: 0;
        }
    }
    .btn {
        //width: 100%;
        padding: 17px 0;
        font-size: 2.2rem;
        @include for-tb() {
            font-size: 1.9rem;
        }
        @include for-sp() {
            font-size: 1.4rem;
        }
        .btn-label {
            padding-right: 0;
            &:before {
                display: none;
            }
        }
        &:before {
            background: none;
        }
    }
}

.top-service-btn {
    text-align: right;
    margin-top: 50px;
    .btn {
        width: 200px;
        height: 46px;
        font-size: 2rem;
    }
}

.top-news-btn {
    //text-align: right;
    margin-top: 50px;
    .btn {
        width: 200px;
        height: 46px;
        font-size: 1.8rem;
    }
    .btn-top-news {
        margin-right: 100px;
    }
}

.top-staff {
    position: relative;
    padding: 150px 0 80px;
    @include for-tb() {
        padding-top: 70px;
    }
    @include for-sp() {
        padding: 40px 0 50px;
    }
    &:before {
        content: "";
        width: 65.1vw;
        height: 1px;
        background: $RED;
        position: absolute;
        right: 0;
        bottom: 0;
        @include for-tb() {
            width: 40vw;
        }
    }
}

.top-staff.anm {
    &:before {
        transform: scaleX(0);
        transform-origin: right center;
        transition: transform 0.5s $ease7 0.8s;
    }
    &.is-show {
        &:before {
            transform: scaleX(1);
        }
    }
}

.top-staff-box {
    width: 1000px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    position: relative;
    z-index: 2;
    @include for-tb() {
        width: 84vw;
        display: block;
    }
}

.top-staff-image {
    width: 584px;
    margin-left: -146px;
    position: relative;
    @media screen and (max-width: 1200px) {
        margin-left: calc((1000px - 100vw) / 2);
        width: 520px;
        img {
            @include max(w);
        }
    }
    @include for-tb() {
        width: 100%;
        padding-left: 20%;
        margin-top: 35px;
        margin-left: 0;
    }
    @include for-sp() {
        padding-left: 0;
    }
}

.top-staff-btn {
    position: absolute;
    top: 223px;
    right: -10px;
    @media screen and (max-width: 1200px) {
        top: 190px;
    }
    @include for-tb() {
        top: auto;
        bottom: 30px;
    }
    .btn {
        @extend .prompt;
        width: 220px;
        height: 46px;
        padding: 13px 0;
        font-size: 2rem;
        @include for-sp() {
            width: 200px;
            font-size: 1.7rem;
        }
        &:after {
            opacity: 0.93;
        }
    }
}

.top-staff-contents {
    width: 480px;
    @include for-tb() {
        width: 100%;
    }
}

.top-staff-text {
    text-align: justify;
    margin-top: 60px;
    @include for-tb() {
        margin-top: 40px;
    }
    @include for-sp() {
        margin-top: 24px;
    }
}

.top-seminar {
    position: relative;
    padding: 90px 0 110px;
    @include for-sp() {
        padding: 40px 0 50px;
    }
    &:before {
        content: "";
        width: 60.4vw;
        height: 1px;
        background: $RED;
        position: absolute;
        left: 0;
        bottom: 0;
        @include for-tb() {
            width: 40vw;
        }
    }
}

.top-seminar.anm {
    &:before {
        transform: scaleX(0);
        transform-origin: left center;
        transition: transform 0.5s $ease7 0.8s;
    }
    &.is-show {
        &:before {
            transform: scaleX(1);
        }
    }
}

.top-seminar-box {
    width: 1000px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 2;
    @include for-tb() {
        display: block;
        width: 84vw;
    }
}

.top-seminar-image {
    width: 584px;
    margin-right: -146px;
    position: relative;
    @media screen and (max-width: 1200px) {
        margin-right: calc((1000px - 100vw) / 2);
        width: 520px;
        img {
            @include max(w);
        }
    }
    @include for-tb() {
        width: 80%;
        margin-top: 35px;
    }
    @include for-sp() {
        width: 100%;
    }
}

.top-seminar-btn {
    position: absolute;
    top: 27px;
    left: -18px;
    .btn {
        @extend .prompt;
        width: 200px;
        padding: 13px 0;
        font-size: 2rem;
        @include for-sp() {
            width: 180px;
            font-size: 1.7rem;
        }
        &:after {
            opacity: 0.93;
        }
    }
}

.top-seminar-contents {
    width: 480px;
    @include for-tb() {
        width: 100%;
    }
}

.top-seminar-text {
    text-align: justify;
    margin-top: 60px;
    @include for-tb() {
        margin-top: 40px;
    }
}