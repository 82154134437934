@charset "UTF-8";
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

html {
  line-height: 1;
}

ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

q, blockquote {
  quotes: none;
}

q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none;
}

a img {
  border: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

*, :before, :after {
  box-sizing: border-box;
  word-break: break-all;
}

html {
  font-size: 62.5%;
}

img {
  vertical-align: middle;
}

ol {
  list-style-position: inside;
}

strong {
  font-weight: bold;
  color: #3E3A39;
}

sup, sub {
  height: 0;
  line-height: 1;
  vertical-align: baseline;
  _vertical-align: bottom;
  position: relative;
  font-size: 1rem;
}

sup {
  bottom: 1ex;
}

sub {
  top: 0.5ex;
}

body {
  font-size: 1.6rem;
  font-family: YakuHanJP_Noto, "Roboto", "Noto Sans JP", "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, "Hiragino Kaku Gothic ProN", sans-serif;
  color: #383838;
  line-height: 1.875;
  letter-spacing: 0.12em;
  font-feature-settings: "palt";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  font-weight: 500;
}

@media (max-width: 559px) {
  body {
    font-size: 1.4rem;
  }
}
::selection {
  background: #999;
  color: #fff;
}

.prompt, .top-seminar-btn .btn, .top-staff-btn .btn, .top-about-btn .btn, .key-slide-title .key-tit-en, .top-tit-en, .title-text h1, .foot-address-map .btn-map, .cta-tel-number, .head-tel, .gnavi-en {
  font-family: "Prompt", sans-serif;
}

.textjustify {
  width: max-content;
  text-align-last: justify;
}
@media (max-width: 350px) {
  .textjustify {
    width: 100%;
    text-align-last: left;
  }
}

input, button, textarea, select {
  font-family: inherit;
}

input[type=button], input[type=submit] {
  font-family: inherit;
  appearance: none;
  margin: 0;
}

input[type=text], input[type=number], input[type=tel], input[type=password], input[type=email], textarea {
  appearance: none;
  width: 100%;
  border: 1px solid #8D8D8D;
  padding: 3px 5px;
  border-radius: 0;
  background: transparent;
}

@media (max-width: 559px) {
  input[type=text], input[type=number], input[type=password], input[type=email], textarea, select {
    font-size: 1.6rem;
  }
}
.select-wrap {
  position: relative;
  display: inline-block;
  max-width: 100%;
}
.select-wrap:after {
  content: "›";
  color: #3E3A39;
  position: absolute;
  right: 8px;
  top: calc(50% - 0.5em);
  line-height: 1;
  transform: rotate(90deg);
  z-index: 1;
  font-size: 1.4rem;
}
.select-wrap:before {
  content: "";
  width: 1px;
  height: 100%;
  position: absolute;
  right: 25px;
  top: 0;
  background: #8D8D8D;
}

select {
  margin: 0;
  max-width: 100%;
  border: 1px solid #8D8D8D;
  appearance: none;
  border-radius: 0;
  padding: 3px 33px 3px 8px;
  background: transparent;
  position: relative;
  z-index: 2;
}

select::-ms-expand {
  display: none;
}

input[type=radio], input[type=checkbox] {
  margin-right: 5px;
}

.radio {
  display: inline-block;
  cursor: pointer;
  position: relative;
  line-height: 1;
  padding: 8px 5px 8px 27px;
  margin-right: 30px;
  margin-top: 0 !important;
  font-size: 1.7rem;
}
.radio input {
  display: none;
}
.radio input:checked + .outer .inner {
  opacity: 1;
  transform: scale(1);
}
.radio input:checked + .outer {
  border: 2px solid #EA5550;
}
.radio input:focus + .outer .inner {
  opacity: 1;
  background-color: #EA5550;
}
.radio input:disabled + .outer {
  background: #CCC;
}
.radio .outer {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 6px;
  left: 0;
  margin: 0;
  display: block;
  border: 2px solid #3E3A39;
  border-radius: 50%;
  transition: border 0.2s linear;
}
.radio .inner {
  width: 10px;
  height: 10px;
  display: block;
  border-radius: 50%;
  background-color: #EA5550;
  opacity: 0;
  position: absolute;
  left: 3px;
  top: 3px;
  overflow: hidden;
  text-indent: -9999px;
  transition: opacity 0.1s linear, transform 0.3s cubic-bezier(0.1, 0.82, 0.1, 1);
  transform: scale(0.7);
}
.radio:hover .outer {
  border-color: rgba(234, 85, 80, 0.5);
}

.win .radio {
  padding: 13px 5px 13px 27px;
}
.win .radio .outer {
  top: 11px;
}

:placeholder-shown {
  color: #bbb;
}

::-webkit-input-placeholder {
  color: #bbb;
}

::-moz-placeholder {
  color: #bbb;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #bbb;
}

a {
  text-decoration: none;
  transition: opacity 0.3s cubic-bezier(0.25, 0.48, 0.63, 0.93);
}
a:hover {
  text-decoration: underline;
}
a:hover img {
  opacity: 0.7;
  transition: opacity 0.3s cubic-bezier(0.25, 0.48, 0.63, 0.93);
}

.tbdisp {
  display: none;
}

.spdisp {
  display: none;
}

@media (max-width: 959px) {
  .tbdisp {
    display: block;
  }
  .tbhide {
    display: none;
  }
}
@media (max-width: 559px) {
  .spdisp {
    display: block;
  }
  .sphide {
    display: none;
  }
}
.cf:after {
  content: "";
  display: block;
  clear: both;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.left {
  text-align: left;
}

.fl-left {
  float: left;
}

.fl-right {
  float: right;
}

.cl {
  clear: both;
}

.btn {
  display: inline-block;
  border-radius: 8px;
  padding: 13px 20px;
  line-height: 1;
  color: #fff;
  text-align: center;
  position: relative;
  overflow: hidden;
}
.btn:before, .btn:after {
  content: "";
  width: 100%;
  height: 100%;
}
.btn:after {
  background: #EA5550;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  transition: opacity 0.3s cubic-bezier(0.25, 0.48, 0.63, 0.93);
}
.btn:before {
  background: rgba(255, 255, 255, 0.15);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.25, 0.48, 0.63, 0.93);
}
.btn:hover {
  text-decoration: none;
  text-shadow: 0 0 6px rgba(255, 255, 255, 0.2);
}
.btn:hover:before {
  opacity: 1;
}
.btn:hover .btn-label:before {
  right: -7px;
}
.btn .btn-label {
  display: inline-block;
  position: relative;
  z-index: 3;
  padding-right: 45px;
}
.btn .btn-label:before {
  content: "";
  width: 23px;
  height: 5px;
  background: url(../images/icon_btn_arrow.png) 0 0 no-repeat;
  background-size: cover;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: right 0.2s ease;
}

.btn-grad:after {
  background: linear-gradient(100deg, #333 0%, #333 60%, #B94D49);
}
.btn-area {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.l {
  font-size: 1.8rem;
  display: inline-block;
}

.s {
  font-size: 1.4rem;
  display: inline-block;
}

.xs {
  font-size: 1.2rem;
  display: inline-block;
}

@media (max-width: 559px) {
  .xs {
    font-size: 1rem;
  }
  .s {
    font-size: 1.2rem;
  }
  .l {
    font-size: 1.6rem;
  }
}
.red {
  color: #EA5550;
}

table {
  width: 100%;
  font-size: 1.6rem;
  line-height: 1.5;
}
table th, table td {
  padding: 16px 10px;
  border: 1px solid #8D8D8D;
}
table thead th {
  font-weight: bold;
  text-align: center;
}
table tbody th {
  font-weight: bold;
}

@media (max-width: 559px) {
  table {
    font-size: 1.4rem;
  }
  table:not(.nobreak) tr, table:not(.nobreak) th, table:not(.nobreak) td {
    display: block;
    border: none;
    padding: 0;
    width: 100%;
  }
  table:not(.nobreak) tr {
    border-bottom: 1px solid #8D8D8D;
    padding: 16px 0;
  }
  table:not(.nobreak) td {
    margin-top: 5px;
  }
}
hr {
  border: none;
  border-bottom: 1px solid #8D8D8D;
  padding-top: 80px;
  margin-bottom: 80px;
}

.paginate {
  padding-top: 64px;
  width: 960px;
  margin: 0 auto;
}

.paginate-list {
  display: flex;
  justify-content: center;
  position: relative;
}
.paginate-list li {
  width: 36px;
  padding-left: 0;
  margin: 0 10px;
}
.paginate-list li:before {
  display: none;
}
.paginate-list .number {
  display: block;
  font-size: 1.6rem;
  text-align: center;
  line-height: 36px;
  height: 36px;
  letter-spacing: 0;
  color: #383838;
  border: 1px solid #3E3A39;
}
.paginate-list a.number {
  transition: background 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.paginate-list a.number:hover {
  background: #F5F5F5;
  text-decoration: none;
  color: #EA5550;
  border: 1px solid #EA5550;
}
.paginate-list .disabled {
  color: #8D8D8D;
}
.paginate-list .disabled .number {
  color: #8D8D8D;
}
.paginate-list .current .number {
  background: #F5F5F5;
  pointer-events: none;
}
.paginate-list .list {
  width: auto;
}
.paginate-list .prev, .paginate-list .next {
  position: absolute;
  top: 0;
  color: #999;
}
.paginate-list .prev .number, .paginate-list .next .number {
  font-size: 2rem;
  border: none;
}
.paginate-list .prev {
  left: 0;
}
.paginate-list .next {
  right: 0;
}
.paginate-list a.prev {
  color: #3E3A39;
}
.paginate-list a.prev:hover {
  color: #EA5550;
  text-decoration: none;
}
.paginate-list a.next {
  color: #3E3A39;
}
.paginate-list a.next:hover {
  color: #EA5550;
  text-decoration: none;
}

@media (max-width: 559px) {
  .paginate {
    width: 100%;
    padding: 30px 4% 0;
  }
}
@media (max-width: 559px) {
  .paginate-list li {
    width: 30px;
    margin: 0 2px;
  }
  .paginate-list .number {
    font-size: 1.3rem;
    height: 30px;
    line-height: 30px;
  }
}
.anm {
  position: relative;
  top: 50px;
  opacity: 0;
  z-index: 2;
  transition: top 1.2s cubic-bezier(0.1, 0.82, 0.1, 1) 0.1s, opacity 0.4s linear 0.1s;
}
.anm.is-show {
  top: 0;
  opacity: 1;
}

#wrapper {
  width: 100%;
  min-width: 1000px;
  overflow: hidden;
}

@media (max-width: 959px) {
  #wrapper {
    min-width: 0;
  }
}
header {
  height: 140px;
  padding: 0 3vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 999;
}
@media screen and (max-width: 1360px) {
  header {
    padding: 0 2vw;
  }
}
@media screen and (max-width: 1200px) {
  header {
    height: 100px;
  }
}
@media (max-width: 559px) {
  header {
    height: 80px;
    padding: 0 4vw;
  }
}

.logo {
  padding-bottom: 10px;
}
.logo img {
  width: 302px;
}
@media screen and (max-width: 1200px) {
  .logo {
    width: 240px;
  }
  .logo img {
    width: 100%;
    height: auto;
  }
}
@media (max-width: 559px) {
  .logo {
    width: 220px;
  }
}

#menu-btn {
  display: none;
}

@media (max-width: 959px) {
  #menu-btn {
    display: block;
    width: 60px;
    height: 60px;
    background: #EA5550;
    border-radius: 8px;
    position: fixed;
    right: 20px;
    top: 20px;
    cursor: pointer;
    z-index: 999;
    overflow: hidden;
  }
  #menu-btn .menu-line {
    display: block;
    width: 30px;
    height: 2px;
    border-radius: 1px;
    background: #fff;
    position: absolute;
    left: calc(50% - 15px);
  }
  #menu-btn .menu-line:nth-child(1) {
    top: 21px;
    transition: top 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.2s, transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.05s;
  }
  #menu-btn .menu-line:nth-child(2) {
    top: 29px;
    transition: transform 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
  }
  #menu-btn .menu-line:nth-child(3) {
    top: 37px;
    transition: top 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.2s, transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.05s;
  }
  #menu.open #menu-btn .menu-line:nth-child(1) {
    top: 29px;
    transform: rotate(-135deg);
    transition: top 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.05s, transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.1s;
  }
  #menu.open #menu-btn .menu-line:nth-child(2) {
    transform: scaleX(0);
  }
  #menu.open #menu-btn .menu-line:nth-child(3) {
    top: 29px;
    transform: rotate(135deg);
    transition: top 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.05s, transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.1s;
  }
  #menu.open .navi-wrap {
    visibility: visible;
    opacity: 1;
  }
}
@media (max-width: 559px) {
  #menu-btn {
    width: 50px;
    height: 50px;
    right: 5px;
    top: 10px;
  }
  #menu-btn .menu-line {
    width: 26px;
    left: calc(50% - 13px);
  }
  #menu-btn .menu-line:nth-child(1) {
    top: 16px;
  }
  #menu-btn .menu-line:nth-child(2) {
    top: 24px;
  }
  #menu-btn .menu-line:nth-child(3) {
    top: 32px;
  }
  #menu.open #menu-btn .menu-line:nth-child(1), #menu.open #menu-btn .menu-line:nth-child(3) {
    top: 24px;
  }
}
.navi-wrap {
  display: flex;
  align-items: center;
}
@media (max-width: 959px) {
  .navi-wrap {
    display: block;
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    width: 100%;
    height: 100%;
    padding: 80px 10vw;
    background: rgba(234, 85, 80, 0.85);
    backdrop-filter: blur(3px);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    position: fixed;
    z-index: 998;
    top: 0;
    left: 0;
  }
}

.gnavi-list {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  position: relative;
}
@media (max-width: 959px) {
  .gnavi-list {
    flex-direction: column;
    margin-right: 0;
    justify-content: center;
    align-items: flex-start;
    padding-bottom: 60px;
  }
  .gnavi-list:before {
    content: "";
    width: 60px;
    height: 1px;
    background: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
@media (max-width: 559px) {
  .gnavi-list {
    padding-bottom: 0;
  }
  .gnavi-list:before {
    display: none;
  }
}
.gnavi-list li {
  text-align: center;
  position: relative;
  line-height: 1;
  /*&:nth-child(5){
  	a:before{background: #fff;}
  }*/
}
@media (max-width: 959px) {
  .gnavi-list li {
    margin-top: 40px;
  }
}
@media (max-width: 559px) {
  .gnavi-list li {
    margin-top: 24px;
  }
}
.gnavi-list li:before {
  content: "";
  width: 1px;
  height: 12px;
  background: #8D8D8D;
  position: absolute;
  left: 0;
  top: 3px;
  transform: scaleX(0.6);
  /*@media screen and (max-width:1200px){
  	display: none;
  }*/
}
.gnavi-list li:first-child:before {
  display: none;
}
.gnavi-list a {
  display: block;
  color: #3E3A39;
  padding: 0 27px;
  position: relative;
  transition: opacity 0.3s linear;
}
@media screen and (max-width: 1550px) {
  .gnavi-list a {
    padding: 0 20px;
  }
}
@media screen and (max-width: 1400px) {
  .gnavi-list a {
    padding: 0 16px;
  }
}
@media screen and (max-width: 1250px) {
  .gnavi-list a {
    padding: 0 12px;
  }
}
@media screen and (max-width: 1150px) {
  .gnavi-list a {
    padding: 0 8px;
  }
}
@media screen and (max-width: 1000px) {
  .gnavi-list a {
    padding: 0 4px;
  }
}
@media (max-width: 959px) {
  .gnavi-list a {
    color: #fff;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    padding: 0;
  }
}
.gnavi-list a:before {
  content: "";
  width: 40px;
  height: 1px;
  background: #EA5550;
  position: absolute;
  left: 50%;
  top: 26px;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.25, 0.48, 0.63, 0.93), width 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
@media (max-width: 959px) {
  .gnavi-list a:before {
    display: none;
  }
}
.gnavi-list .active a,
.gnavi-list a:hover {
  text-decoration: none;
}
.gnavi-list .active a:before,
.gnavi-list a:hover:before {
  opacity: 1;
  width: 20px;
}
.gnavi-list a:hover {
  opacity: 0.8;
}

.gnavi-jp {
  display: block;
  font-size: 1.6rem;
  font-weight: 700;
}
@media screen and (max-width: 1360px) {
  .gnavi-jp {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 1100px) {
  .gnavi-jp {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 1050px) {
  .gnavi-jp {
    font-size: 1.3rem;
  }
}
@media (max-width: 959px) {
  .gnavi-jp {
    font-size: 1.7rem;
    margin-top: 10px;
  }
}
@media (max-width: 559px) {
  .gnavi-jp {
    font-size: 1.2rem;
    margin-top: 6px;
  }
}

.gnavi-en {
  font-size: 1.3rem;
  letter-spacing: 0.05em;
  display: block;
  margin-top: 17px;
}
@media screen and (max-width: 1360px) {
  .gnavi-en {
    font-size: 1.1rem;
  }
}
@media (max-width: 959px) {
  .gnavi-en {
    font-size: 4.4rem;
    margin-top: 0;
  }
}
@media (max-width: 559px) {
  .gnavi-en {
    font-size: 2.4rem;
  }
}

.gnavi-contact-en {
  margin-top: 7.5px;
}
@media (max-width: 959px) {
  .gnavi-contact-en {
    margin-top: 0;
  }
}

.head-contact {
  text-align: center;
  margin-right: 10px;
}
@media screen and (max-width: 1360px) {
  .head-contact {
    width: 170px;
    margin-right: 0;
  }
}
@media (max-width: 959px) {
  .head-contact {
    margin-top: 60px;
    display: flex;
    width: 100%;
    align-items: center;
  }
}
@media (max-width: 559px) {
  .head-contact {
    margin-top: 60px;
    display: block;
  }
}

.head-contact-btn .btn-head-contact {
  display: block;
  text-align: center;
  font-size: 1.8rem;
  background: #383838;
  color: #fff;
  line-height: 1;
  width: 198px;
  padding: 15px 0;
  border-radius: 8px;
  transition: all 0.3s cubic-bezier(0.25, 0.48, 0.63, 0.93);
}
@media screen and (max-width: 1360px) {
  .head-contact-btn .btn-head-contact {
    width: 170px;
  }
}
@media screen and (max-width: 1200px) {
  .head-contact-btn .btn-head-contact {
    font-size: 1.3rem;
  }
}
@media (max-width: 959px) {
  .head-contact-btn .btn-head-contact {
    font-size: 2.1rem;
    width: auto;
    padding: 16px 20px;
    background: #fff;
    color: #EA5550;
    font-weight: 700;
  }
}
@media (max-width: 559px) {
  .head-contact-btn .btn-head-contact {
    width: 100%;
    font-size: 1.6rem;
    padding: 12px 0;
  }
}
.head-contact-btn .btn-head-contact:hover {
  text-decoration: none;
  background: #555;
  text-shadow: 0 0 6px rgba(255, 255, 255, 0.2);
}

.head-tel {
  font-size: 2.8rem;
  letter-spacing: 0.02em;
  color: #fff;
}
@media screen and (max-width: 1360px) {
  .head-tel {
    font-size: 2.3rem;
  }
}
@media (max-width: 959px) {
  .head-tel {
    margin-left: 30px;
    font-size: 4rem;
    line-height: 1;
    border-bottom: 1px solid #fff;
    padding-bottom: 3px;
  }
  .head-tel:before {
    content: "tel. ";
    font-size: 2rem;
  }
}
@media (max-width: 559px) {
  .head-tel {
    width: 100%;
    font-size: 2.8rem;
    margin-left: 0;
    margin-top: 20px;
    border: none;
  }
  .head-tel a {
    color: #fff;
  }
}

.cta {
  padding: 120px 0 145px;
}
@media (max-width: 559px) {
  .cta {
    padding: 60px 0;
  }
}

.cta-tit {
  text-align: center;
  font-size: 2.4rem;
  color: #3E3A39;
  letter-spacing: 0.12em;
}
@media (max-width: 559px) {
  .cta-tit {
    font-size: 1.8rem;
    line-height: 1.5;
  }
}

.cta-btn {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}
@media (max-width: 959px) {
  .cta-btn {
    width: 90vw;
    margin: 25px auto 0;
  }
}
@media (max-width: 559px) {
  .cta-btn {
    width: 80vw;
    display: block;
    margin: 70px auto;
  }
}

.cta-contact {
  margin-right: 30px;
  height: 100px;
  width: 464px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.6rem;
  flex-direction: column;
}
@media (max-width: 959px) {
  .cta-contact {
    width: 48%;
    margin-right: 4%;
    font-size: 2.2rem;
  }
}
@media (max-width: 559px) {
  .cta-contact {
    width: 100%;
    height: 80px;
    font-size: 1.8rem;
  }
}
.cta-contact .btn-label {
  font-size: 3.6rem;
}
@media (max-width: 959px) {
  .cta-contact .btn-label {
    font-size: 3rem;
  }
}
@media (max-width: 559px) {
  .cta-contact .btn-label {
    font-size: 2rem;
  }
}
.cta-contact .btn-label:before {
  width: 30px;
  height: 7px;
}

.cta-tel {
  width: 464px;
  height: 100px;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  pointer-events: none;
}
@media (max-width: 959px) {
  .cta-tel {
    width: 48%;
  }
}
@media (max-width: 559px) {
  .cta-tel {
    width: 100%;
    height: 80px;
    margin-top: 16px;
    pointer-events: auto;
  }
}
.cta-tel:after {
  background: linear-gradient(100deg, #333 0%, #333 70%, #964543);
}
.cta-tel a:hover {
  text-decoration: none;
}

.cta-tel-label {
  display: block;
  font-size: 1.5rem;
  letter-spacing: 0.15em;
  position: relative;
  z-index: 2;
}
@media (max-width: 559px) {
  .cta-tel-label {
    font-size: 1.3rem;
  }
}

.cta-tel-label-accept {
  font-size: 1.3rem;
}

.cta-tel-number {
  display: block;
  margin-top: 7px;
  margin-bottom: 7px;
  font-size: 4rem;
  position: relative;
  z-index: 2;
}
@media (max-width: 959px) {
  .cta-tel-number {
    font-size: 3rem;
  }
}
@media (max-width: 559px) {
  .cta-tel-number {
    font-size: 2.8rem;
  }
}

.cta-contact-label {
  display: block;
  font-size: 1.5rem;
  letter-spacing: 0.15em;
  position: relative;
  z-index: 2;
}
@media (max-width: 559px) {
  .cta-contact-label {
    font-size: 1.3rem;
  }
}

.btn-contact-ja {
  font-size: 3rem;
}

.btn-contact-en {
  display: block;
  margin-top: 7px;
  font-size: 2rem;
  position: relative;
  z-index: 2;
}

footer {
  background: #2b2b2b;
  padding: 100px 0 30px;
  position: relative;
}
@media (max-width: 559px) {
  footer {
    padding: 60px 0 20px;
  }
}

.foot-box {
  padding: 0 16vw;
}
@media screen and (max-width: 1200px) {
  .foot-box {
    padding: 0 6vw;
  }
}
@media (max-width: 559px) {
  .foot-box {
    padding: 0 10vw;
    display: flex;
    flex-direction: column-reverse;
  }
}

.foot-siteindex {
  display: flex;
  padding-bottom: 110px;
}
@media (max-width: 559px) {
  .foot-siteindex {
    padding-bottom: 60px;
    margin-top: 20px;
  }
}

.foot-siteindex-list {
  font-size: 1.5rem;
  font-weight: 700;
  margin-right: 6vw;
  line-height: 2;
}
@media (max-width: 559px) {
  .foot-siteindex-list {
    font-size: 1.3rem;
    font-weight: normal;
    margin-right: 30px;
  }
}
.foot-siteindex-list li:before {
  content: "・";
  color: #c6c6c6;
}
.foot-siteindex-list a {
  color: #c6c6c6;
}

.foot-address {
  position: absolute;
  right: 4vw;
  bottom: 46px;
  text-align: right;
}
@media (max-width: 559px) {
  .foot-address {
    position: static;
    text-align: left;
  }
}

@media (max-width: 559px) {
  .foot-logo {
    width: 100px;
  }
  .foot-logo a {
    display: block;
  }
  .foot-logo img {
    width: 100%;
    height: auto;
  }
}

.foot-address-text {
  font-size: 1.3rem;
  color: #c6c6c6;
  margin-top: 40px;
}
@media (max-width: 559px) {
  .foot-address-text {
    margin-top: 24px;
    font-size: 1.2rem;
  }
}

.foot-address-map {
  margin-top: 5px;
}
.foot-address-map .btn-map {
  display: inline-block;
  width: 128px;
  border-radius: 8px;
  border: 1px solid #c6c6c6;
  line-height: 1;
  font-size: 2rem;
  text-align: center;
  padding: 8px 0;
  color: #c6c6c6;
  transform: scale(0.5);
  transform-origin: right top;
  opacity: 0.8;
  transition: all 0.3s cubic-bezier(0.25, 0.48, 0.63, 0.93);
}
@media (max-width: 559px) {
  .foot-address-map .btn-map {
    transform-origin: left top;
  }
}
.foot-address-map .btn-map:hover {
  text-decoration: none;
  background: #c6c6c6;
  color: #282828;
}

.copyright {
  display: block;
  text-align: center;
  color: #c6c6c6;
  font-size: 1.3rem;
  font-weight: 300;
  font-family: "Noto Sans JP", sans-serif;
}
@media (max-width: 559px) {
  .copyright {
    font-size: 1rem;
  }
}

.copymark {
  font-family: Verdana, "Droid Sans";
}

.tel-number {
  color: #fff;
}

.page-title {
  position: relative;
  margin-bottom: 100px;
  /*.credit {
  	text-align: right;
  	width: 1100px;
  	position: absolute;
  	@media screen and (max-width: 1280px) {
  		width: 100%;
  	}
  	@include for-tb() {
  		padding-right: 10px;
  	}
  	@include for-sp() {
  		font-size: 1.2rem;
  	}
  }*/
}
.page-title img {
  height: 288px;
  width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  overflow: hidden;
  margin-left: -100px;
  object-fit: cover;
  object-position: 50% 0%;
}
@media screen and (max-width: 1280px) {
  .page-title {
    margin-bottom: -30px;
  }
  .page-title img {
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 959px) {
  .page-title {
    margin-bottom: 100px;
  }
  .page-title img {
    display: block;
    width: 100%;
    height: auto;
  }
}
@media (max-width: 559px) {
  .page-title img {
    height: 132px;
  }
}

.main {
  padding: 70px 0;
  text-align: center;
}
@media (max-width: 959px) {
  .main {
    padding: 0;
  }
}

.page-body p {
  margin-bottom: 30px;
  color: #3E3A39;
}
@media (max-width: 959px) {
  .page-body p {
    font-size: 1.4rem;
  }
}

.page-body ul {
  margin-bottom: 10px;
  color: #3E3A39;
}

.page-body h2 {
  font-size: 2.4rem;
  line-height: 1.8;
  color: #3E3A39;
  margin-bottom: 30px;
  font-weight: 500;
}
@media (max-width: 959px) {
  .page-body h2 {
    font-size: 1.9rem;
  }
}

.page-body h3 {
  text-align: center;
  margin-bottom: 20px;
  color: #3E3A39;
  font-weight: 500;
}
.page-body h3:hover {
  color: #8D8D8D;
}

.block {
  max-width: 1000px;
  margin: 0 auto 80px;
}
@media screen and (max-width: 1280px) {
  .block {
    width: 100%;
    max-width: 100%;
    padding: 60px 8% 0;
    margin: 0 auto 60px;
  }
}
@media (max-width: 959px) {
  .block {
    padding-top: 0px;
    padding-left: 35px;
    padding-right: 35px;
  }
}
@media (max-width: 559px) {
  .block {
    margin: 0 auto 40px;
  }
}

.breadcrumb {
  font-size: 1.3rem;
  line-height: 1;
  text-align: left;
  margin-left: -100px;
}
@media screen and (max-width: 1280px) {
  .breadcrumb {
    margin-left: auto;
    margin-top: -50px;
  }
}
@media (max-width: 959px) {
  .breadcrumb {
    display: none;
  }
}

.breadcrumb li {
  display: inline-block;
  color: #3E3A39;
}

.breadcrumb li::before {
  content: "/";
  display: inline-block;
  margin: 0 5px 0 2px;
  font-size: 1.8rem;
  color: #888;
}

.breadcrumb li:first-child::before {
  content: none;
}

.breadcrumb a {
  text-decoration: none;
  color: #888;
}
.breadcrumb a:hover {
  color: #3E3A39;
}

.title-image {
  position: relative;
}
@media (max-width: 959px) {
  .title-image {
    margin-right: calc(50% - 50vw);
    margin-left: calc(50% - 50vw);
  }
}

.title-text {
  position: absolute;
  bottom: calc(10% - 120px);
  width: 100%;
  text-align: left;
  padding-left: 20px;
  left: -10%;
}
@media screen and (max-width: 1280px) {
  .title-text {
    left: 0;
  }
}
@media (max-width: 959px) {
  .title-text {
    bottom: calc(10% - 180px);
    padding-left: 15px;
    padding-right: 0px;
  }
}
.title-text h1 {
  display: inline-block;
  background: linear-gradient(100deg, #333 0%, #333 70%, #964543);
  padding: 13px 20px;
  line-height: 1;
  color: #fff;
  text-align: center;
  position: relative;
  overflow: hidden;
  top: -60px;
  padding: 13px 0;
  padding-right: 80px;
  padding-left: 50px;
  z-index: 3;
  height: 86px;
  left: 20px;
}
@media screen and (max-width: 1280px) {
  .title-text h1 {
    font-size: 1.7rem;
  }
}
@media (max-width: 959px) {
  .title-text h1 {
    width: auto;
    word-break: revert;
    background: none;
    color: #3E3A39;
    text-align: left;
    padding-left: 0px;
    padding-right: 0px;
  }
}
.title-text h1:after {
  opacity: 0.93;
}
.title-text h1 .title-text-en {
  font-size: 5.4rem;
}
@media (max-width: 959px) {
  .title-text h1 .title-text-en {
    font-size: 3.6rem;
    line-height: 1.2;
  }
}
.title-text h1 .title-text-ja {
  font-size: 2.2rem;
}
@media (max-width: 959px) {
  .title-text h1 .title-text-ja {
    font-size: 1.5rem;
    display: block;
  }
}
.cta-lower {
  padding: 0 0 145px;
}
@media (max-width: 559px) {
  .cta-lower {
    padding: 0;
  }
}

@media (max-width: 959px) {
  .paginate {
    width: auto;
  }
}

.video-container video {
  width: 80%;
  max-width: 960px;
  height: auto;
  display: block;
  margin: 0 auto;
}
@media (max-width: 559px) {
  .video-container video {
    width: 100%;
    max-width: 100%;
  }
}

#home {
  /*.credit-box {
  	width: 1200px;
  	margin: 0 auto;
  	position: relative;
  	z-index: 2;
  	@include for-tb() {
  		width: 100%;
  		font-size: 1.4rem;
  	}
  	@include for-sp() {
  		font-size: 1rem;
  	}
  	.credit {
  		position: absolute;
  		//text-align: right;
  		right: 0;
  		bottom: 0;
  		@media screen and (max-width: 1200px) {
  			right: 5%;
  		}
  		@media screen and (max-width: 1160px) {
  			right: 8%;
  		}
  		@media screen and (max-width: 1120px) {
  			right: 12%;
  		}
  		@media screen and (max-width: 1080px) {
  			right: 15%;
  		}
  		@media screen and (max-width: 1040px) {
  			right: 18%;
  		}
  		@media screen and (max-width: 1000px) {
  			right: 21%;
  		}
  		@include for-tb() {
  			right: 0;
  		}
  	}
  }*/
}
#home .info-text {
  margin-left: 20px;
}
#home .top-seminar-image p {
  position: absolute;
  right: 5%;
  bottom: 25%;
  z-index: 2;
  color: #fff;
}
@media screen and (max-width: 1200px) {
  #home .top-seminar-image p {
    bottom: 33%;
  }
}
@media (max-width: 959px) {
  #home .top-seminar-image p {
    bottom: 0;
    font-size: 1.4rem;
  }
}
@media (max-width: 559px) {
  #home .top-seminar-image p {
    font-size: 1.2rem;
  }
}

#company body h2 {
  font-size: 2.4rem;
  padding-top: 22px;
}
#company .page-body h2 {
  font-size: 3.2rem;
  line-height: 1.2;
  color: #3E3A39;
  margin-bottom: 30px;
}
#company .page-body h3 {
  text-align: left;
  font-size: 2.4rem;
  margin-bottom: 20px;
}
@media (max-width: 559px) {
  #company .page-body h3 {
    font-size: 1.9rem;
  }
}
#company .company-tb {
  /*.company-detail-sp{
  	display: none;
  	@include for-sp(){
  		display: contents;
  	}
  }*/
}
@media (max-width: 959px) {
  #company .company-tb {
    padding: 0;
    display: block;
    text-align: left;
    width: 100%;
  }
}
#company .company-tb table {
  width: 100%;
  margin-top: 20px;
  table-layout: fixed;
}
@media (max-width: 959px) {
  #company .company-tb table {
    font-size: 1.4rem;
  }
}
#company .company-tb table tbody th {
  font-weight: unset;
  border: none;
  width: 20%;
  padding-left: 20px;
}
@media (max-width: 559px) {
  #company .company-tb table tbody th {
    padding-left: 0px;
    font-size: 1.2rem;
    white-space: nowrap;
  }
}
#company .company-tb table tbody td {
  border: none;
  line-height: 1.8;
}
@media (max-width: 559px) {
  #company .company-tb table tbody td {
    padding-left: 0px;
    font-size: 1.4rem;
  }
}
#company .company-tb table tbody td .company-tb-link {
  color: #EA5550;
}
@media (max-width: 559px) {
  #company .company-tb th,
#company .company-tb td {
    display: block;
    text-align: left;
    width: 100%;
  }
}
#company .company-tb .company-tb-gray {
  background: #F5F5F5;
}
@media (max-width: 559px) {
  #company .company-tb .company-tb-gray {
    background: none;
  }
}
#company .company-tb .company-tb-gray a {
  color: #EA5550;
}
#company .company-tb .company-tb-border {
  border-bottom: 1px solid #3E3A39;
}
#company .company-tb .company-tb-height td {
  height: 125px;
}
@media (max-width: 559px) {
  #company .company-tb .tb-tel a:hover {
    text-decoration-color: #EA5550;
  }
}
#company .company-tb .tel-number {
  color: #3E3A39;
}
@media (max-width: 559px) {
  #company .company-tb .tel-number {
    color: #EA5550;
  }
}
@media screen and (max-width: 1280px) {
  #company .gmap {
    padding: inherit;
  }
}
@media (max-width: 959px) {
  #company .gmap {
    padding: 0;
  }
}
#company .gmap img {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  border: 1px solid #3E3A39;
}
@media (max-width: 559px) {
  #company .gmap img {
    width: 100vw;
    border: none;
    border-top: 1px solid #3E3A39;
    border-bottom: 1px solid #3E3A39;
    margin-left: calc((100vw - 100%) / 2 * -1);
    margin-right: calc((100vw - 100%) / 2 * -1);
  }
}
#company .gmap-text {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 20px;
  padding-bottom: 80px;
  margin-top: 10px;
}
@media (max-width: 959px) {
  #company .gmap-text {
    display: block;
  }
}
@media (max-width: 559px) {
  #company .gmap-text {
    padding-bottom: 40px;
    margin-top: 20px;
  }
}
#company .gmap-text-list {
  width: calc((100% - 20px) / 2);
  margin-bottom: 0;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2;
  text-align: left;
}
@media (max-width: 959px) {
  #company .gmap-text-list {
    width: 100%;
  }
}
@media (max-width: 559px) {
  #company .gmap-text-list {
    font-size: 1.3rem;
    font-weight: normal;
  }
}
#company .gmap-text-list li {
  list-style-type: none;
  font-weight: 500;
}
@media (max-width: 559px) {
  #company .gmap-text-list li {
    font-size: 1.4rem;
  }
}
#company .gmap-text-list .station {
  font-size: 1.8rem;
  margin-bottom: 5px;
  margin-top: 20px;
}
@media (max-width: 559px) {
  #company .gmap-text-list .station {
    margin-bottom: 0;
    font-size: 1.6rem;
  }
}
#company .print-btn-wrap {
  margin-bottom: 80px;
}
@media (max-width: 559px) {
  #company .print-btn-wrap {
    margin-bottom: 60px;
  }
}
#company .print-btn {
  transition: 0.3s;
}
#company .print-btn:hover {
  opacity: 0.7;
  transition: 0.3s;
}
#company .print-btn img {
  width: 300px;
  border: none;
}
@media (max-width: 559px) {
  #company .gmap-inner {
    width: 100vw;
    margin-left: calc((100vw - 100%) / 2 * -1);
    margin-right: calc((100vw - 100%) / 2 * -1);
  }
}
#company .gmap-inner iframe {
  width: 100%;
  border: 1px solid #3E3A39;
}
@media (max-width: 559px) {
  #company .gmap-inner iframe {
    border: none;
    border-bottom: 1px solid #3E3A39;
    border-top: 1px solid #3E3A39;
    height: 375px;
  }
}
#company .gmap-inner p {
  text-align: left;
}
@media (max-width: 559px) {
  #company .gmap-inner p {
    text-align: center;
  }
}
#company .gmap-inner p a {
  color: #EA5550;
}

#staff .staff-title {
  text-align: left;
}
@media (max-width: 559px) {
  #staff .staff-title {
    text-align: center;
  }
}
@media (max-width: 959px) {
  #staff .staff-president {
    padding-top: 60px;
  }
}
#staff .staff-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px 95px;
}
@media screen and (max-width: 1280px) {
  #staff .staff-list {
    gap: 20px 10%;
  }
}
#staff .president {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
}
@media (max-width: 559px) {
  #staff .president {
    display: block;
    justify-content: space-evenly;
    margin-left: calc((100vw - 100%) / 15);
    margin-right: calc((100vw - 100%) / 15);
  }
}
#staff .consultant {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
}
@media (max-width: 559px) {
  #staff .consultant {
    display: block;
    justify-content: space-evenly;
    margin-left: calc((100vw - 100%) / 15);
    margin-right: calc((100vw - 100%) / 15);
  }
}
#staff .advisor {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
}
@media (max-width: 559px) {
  #staff .advisor {
    display: block;
    justify-content: space-evenly;
    margin-left: calc((100vw - 100%) / 15);
    margin-right: calc((100vw - 100%) / 15);
  }
}
#staff .staff-box {
  width: 45%;
}
@media (max-width: 559px) {
  #staff .staff-box {
    width: auto;
    margin-bottom: 30px;
  }
}
#staff .staff-box a {
  text-decoration: none;
  display: flex;
  justify-content: space-between;
}
#staff .staff-box a:hover {
  opacity: 0.7;
  transition: opacity 0.3s cubic-bezier(0.25, 0.48, 0.63, 0.93);
}
#staff .staff-box a:hover img {
  opacity: 0.9;
}
#staff .staff-box:after {
  content: "";
  width: 100%;
  height: 1px;
  background: #ea5550;
  position: absolute;
  right: 0%;
  bottom: 5%;
}
#staff .page-body h3:hover {
  color: #3E3A39;
}
#staff .staff-image {
  width: 150px;
  height: 187px;
  position: relative;
  z-index: 2;
  overflow: hidden;
  margin-bottom: 10px;
}
@media (max-width: 959px) {
  #staff .staff-image {
    width: 125px;
    height: 176px;
    margin-bottom: 0px;
  }
}
@media screen and (max-width: 768px) {
  #staff .staff-image {
    width: 100px;
    height: 151px;
    margin-bottom: -10px;
  }
}
@media (max-width: 559px) {
  #staff .staff-image {
    height: 152px;
  }
}
#staff .staff-image img {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}
@media (max-width: 559px) {
  #staff .staff-image img {
    top: 3%;
  }
}
#staff .staff-contents {
  margin-top: 30px;
}
#staff .staff-post {
  text-align: left;
  color: #3E3A39;
}
@media (max-width: 959px) {
  #staff .staff-post {
    font-size: 1.2rem;
  }
}
#staff .staff-name {
  text-align: left;
  font-size: 2.8rem;
}
@media (max-width: 959px) {
  #staff .staff-name {
    font-size: 2rem;
  }
}
#staff .staff-name br {
  display: block;
  content: "";
  margin-top: -20px;
}
@media (max-width: 959px) {
  #staff .staff-name br {
    margin-top: -10px;
  }
}
#staff .staff-box:nth-child(4n+1) {
  margin-left: 0;
}
@media screen and (max-width: 768px) {
  #staff .staff-detail-block {
    margin-bottom: 35px;
  }
}
#staff .staff-detail {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 80px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  padding-top: 60px;
}
@media (max-width: 959px) {
  #staff .staff-detail {
    justify-content: left;
  }
}
@media screen and (max-width: 768px) {
  #staff .staff-detail {
    margin-bottom: 0px;
  }
}
@media (max-width: 559px) {
  #staff .staff-detail {
    margin-right: 150px;
  }
}
#staff .staff-detail-image img {
  width: 100%;
  height: auto;
}
#staff .staff-detail-post {
  color: #3E3A39;
  font-size: 1.8rem;
  padding-bottom: 20px;
}
@media (max-width: 559px) {
  #staff .staff-detail-post {
    font-size: 1.4rem;
  }
}
#staff .staff-detail-name h1 {
  position: relative;
}
#staff .staff-detail-name:after {
  content: "";
  display: block;
  position: absolute;
  -webkit-transform: translate(0%);
  transform: translate(0%);
  width: 100vw;
  height: 1px;
  background-color: #e65754;
}
#staff .entry-title {
  font-size: 3.4rem;
  line-height: 1.2;
  color: #3E3A39;
  padding-bottom: 30px;
}
@media (max-width: 559px) {
  #staff .entry-title {
    font-size: 2.1rem;
    padding-bottom: 10px;
  }
}
#staff .staff-detail-roman {
  font-size: 1.6rem;
}
@media (max-width: 959px) {
  #staff .staff-detail-roman {
    font-size: 1.3rem;
  }
}
@media (max-width: 559px) {
  #staff .staff-detail-roman {
    font-size: 1.1rem;
  }
}
#staff .staff-detail-contents {
  width: calc(100% - 360px);
  padding-top: 100px;
  -webkit-transition: right 1s cubic-bezier(0.1, 0.82, 0.1, 1) 1s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s;
  transition: top 1.2s cubic-bezier(0.1, 0.82, 0.1, 1) 0.1s, opacity 0.4s linear 0.1s;
  text-align: left;
}
@media (max-width: 959px) {
  #staff .staff-detail-contents {
    margin-left: 40px;
  }
}
@media screen and (max-width: 768px) {
  #staff .staff-detail-contents {
    padding-top: 150px;
  }
}
@media (max-width: 559px) {
  #staff .staff-detail-contents {
    padding-top: 65px;
    white-space: nowrap;
    margin-left: 15px;
  }
}
#staff .staff-detail-image {
  width: 300px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
@media (max-width: 959px) {
  #staff .staff-detail-image {
    width: 240px;
  }
}
@media (max-width: 559px) {
  #staff .staff-detail-image {
    width: 130px;
  }
}
#staff .staff-detail-name {
  margin-bottom: 30px;
  font-weight: 500;
}
#staff .staff-detail-nopic {
  display: -webkit-box;
  display: -ms-flexbox;
  margin-bottom: 80px;
  padding-top: 60px;
}
#staff .staff-detail-nopic .staff-detail-contents {
  padding-top: 0;
}
@media (max-width: 959px) {
  #staff .staff-detail-nopic .staff-detail-contents {
    margin-left: 0;
  }
}
@media screen and (max-width: 768px) {
  #staff .staff-detail-nopic .staff-detail-other {
    display: initial;
    white-space: nowrap;
  }
}
@media (max-width: 559px) {
  #staff .staff-detail-nopic .staff-detail-other {
    white-space: inherit;
  }
}
@media screen and (max-width: 768px) {
  #staff .staff-detail-other {
    display: none;
  }
}
#staff .staff-detail-other-sp {
  display: none;
}
@media screen and (max-width: 768px) {
  #staff .staff-detail-other-sp {
    display: initial;
    text-align: left;
  }
}
@media (max-width: 559px) {
  #staff .staff-detail-other-sp p {
    margin-top: 24px;
  }
}
#staff .staff-detail-body a {
  color: #EA5550;
}
@media screen and (max-width: 768px) {
  #staff .career-detail-title {
    text-align: left;
  }
}
#staff .career-detail {
  display: flex;
}
@media screen and (max-width: 768px) {
  #staff .career-detail {
    display: block;
    padding-right: 0;
  }
}
#staff .career-title {
  display: inline-block;
  border-right: 1px solid #e65754;
  padding-right: 50px;
  text-align: left;
  white-space: nowrap;
}
@media (max-width: 959px) {
  #staff .career-title {
    padding-right: 20px;
  }
}
@media screen and (max-width: 768px) {
  #staff .career-title {
    border: none;
  }
}
#staff .career-detail-tb {
  flex: 1;
  text-align: left;
  margin-left: 50px;
}
@media (max-width: 959px) {
  #staff .career-detail-tb {
    margin-left: 20px;
  }
}
@media screen and (max-width: 768px) {
  #staff .career-detail-tb {
    margin-left: 0px;
  }
}
#staff .career-detail-tb p {
  padding-top: 5px;
  white-space: nowrap;
}
#staff .career-detail-tb dt {
  float: left;
  width: 120px;
}
@media (max-width: 559px) {
  #staff .career-detail-tb dt {
    width: 70px;
  }
}
#staff .career-detail-tb dd {
  white-space: nowrap;
}
@media (max-width: 959px) {
  #staff .career-detail-tb dd {
    white-space: initial;
  }
}
@media (max-width: 559px) {
  #staff .career-detail-tb .career-dd-pc {
    display: none;
  }
}
#staff .career-detail-tb .career-dd-sp {
  display: none;
}
@media (max-width: 559px) {
  #staff .career-detail-tb .career-dd-sp {
    display: block;
  }
}
#staff .career-previous p {
  margin-bottom: 20px;
  margin-top: 5px;
}
#staff .career-tb {
  color: #3E3A39;
}
@media screen and (max-width: 768px) {
  #staff .career-tb {
    font-size: 1.4rem;
  }
}
@media (max-width: 959px) {
  #staff .career-tb-br {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  #staff .position-list {
    font-size: 1.4rem;
  }
}
@media (max-width: 559px) {
  #staff .position-list .position-list-pc {
    display: none;
  }
}
#staff .position-list .position-list-sp {
  display: none;
}
@media (max-width: 559px) {
  #staff .position-list .position-list-sp {
    display: block;
  }
}
@media screen and (max-width: 768px) {
  #staff .book-list {
    font-size: 1.4rem;
  }
}
#staff .career-detail-gray {
  background-color: #F5F5F5;
  text-align: left;
  margin-top: 10px;
  padding-top: 50px;
  height: auto;
  padding-bottom: 50px;
}
#staff .staff-link {
  text-align: right;
  margin-top: 20px;
}
@media (max-width: 559px) {
  #staff .staff-link {
    text-align: center;
  }
}
#staff .staff-link a {
  color: #e65754;
}

#seminar .page-body h3 {
  font-size: 1.8rem;
  text-align: left;
}
@media (max-width: 559px) {
  #seminar .page-body h3 {
    font-size: 1.4rem;
  }
}
#seminar .page-body p {
  margin-bottom: 0;
}
#seminar .title-text {
  width: 110%;
}
@media (max-width: 559px) {
  #seminar .title-text-en {
    line-height: 0;
  }
}
@media (max-width: 559px) {
  #seminar .title-text-en-pub {
    display: block;
    line-height: 0.9;
  }
}
#seminar .seminar-top-tub {
  padding-top: 10px;
}
#seminar .seminar-top-tub-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 20px;
  justify-content: space-evenly;
}
@media (max-width: 559px) {
  #seminar .seminar-top-tub-list {
    justify-content: space-between;
  }
}
#seminar .seminar-top-tub-list li {
  margin-top: 15px;
  line-height: 1;
  width: 32.5%;
}
@media (max-width: 559px) {
  #seminar .seminar-top-tub-list li {
    width: 30%;
  }
}
#seminar .seminar-top-tub-list .btn {
  width: 100%;
  padding: 17px 0;
  font-size: 2.2rem;
}
#seminar .btn-label-seminar {
  display: inline-block;
  position: relative;
  z-index: 3;
}
@media (max-width: 559px) {
  #seminar .btn-label-seminar {
    font-size: 1.6rem;
  }
}
#seminar .btn-label-seminar:before {
  content: "";
  width: 23px;
  height: 5px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: right 0.2s ease;
}
#seminar .seminar-title {
  text-align: left;
}
@media (max-width: 559px) {
  #seminar .seminar-schedule {
    padding-bottom: 70px;
  }
}
#seminar .seminar-schedule:after {
  content: "";
  width: 1000vh;
  height: 1px;
  background: #ea5550;
  position: absolute;
  right: 55%;
  bottom: 0;
}
@media (max-width: 559px) {
  #seminar .seminar-schedule:after {
    content: none;
  }
}
#seminar .seminar-box {
  text-align: left;
  margin-bottom: 50px;
  font-size: 1.6rem;
  position: relative;
}
#seminar .seminar-box dt {
  float: left;
  padding-right: 30px;
  width: 25%;
}
@media (max-width: 559px) {
  #seminar .seminar-box dt {
    display: contents;
  }
}
@media (max-width: 559px) {
  #seminar .seminar-box dd {
    width: auto;
  }
}
#seminar .seminar-box-title p {
  margin-bottom: 10px;
  margin-top: 5px;
  font-size: 1.9rem;
  display: block;
}
@media (max-width: 559px) {
  #seminar .seminar-box-title p {
    line-height: 1.3;
    font-size: 1.6rem;
  }
}
#seminar .seminar-box-title a {
  font-size: 1.9rem;
  color: #e65754;
}
@media (max-width: 559px) {
  #seminar .seminar-box-title a {
    line-height: 1.3;
    font-size: 1.6rem;
  }
}
@media (max-width: 559px) {
  #seminar .seminar-detail-tb {
    position: relative;
  }
}
#seminar .seminar-detail-tb:before {
  content: "";
  width: 100%;
  height: 1px;
  background: #3E3A39;
  position: absolute;
}
#seminar .seminar-tb {
  padding-top: 10px;
}
@media (max-width: 559px) {
  #seminar .seminar-tb {
    background-color: #F5F5F5;
    padding-left: 10px;
    padding-right: 10px;
  }
}
#seminar .seminar-tb a {
  color: #EA5550;
}
@media (max-width: 559px) {
  #seminar .seminar-tb dt {
    padding-bottom: 10px;
    font-size: 1.2rem;
  }
}
@media (max-width: 559px) {
  #seminar .seminar-tb dd {
    padding-bottom: 10px;
    font-size: 1.4rem;
    line-height: 1.2;
  }
}
#seminar .seminar-tb dd ul {
  display: grid;
}
@media (max-width: 559px) {
  #seminar .seminar-tb dd ul {
    gap: 10px;
  }
}
#seminar .seminar-tb .tel-number {
  color: #3E3A39;
}
@media (max-width: 559px) {
  #seminar .seminar-tb .tel-number {
    color: #EA5550;
  }
}
#seminar .seminar-btn {
  text-align: right;
  padding-bottom: 50px;
}
@media (max-width: 559px) {
  #seminar .seminar-btn {
    text-align: center;
    padding-bottom: 0;
  }
}
#seminar .seminar-publish-table {
  margin-bottom: 50px;
}
@media (max-width: 559px) {
  #seminar .seminar-publish-table thead {
    display: none;
  }
}
#seminar .seminar-publish-table tbody {
  font-size: 1.5rem;
}
@media (max-width: 559px) {
  #seminar .seminar-publish-table tbody {
    font-size: 1.2rem;
  }
}
#seminar .seminar-publish-table tbody tr:nth-child(2n) {
  background-color: #F5F5F5;
}
#seminar .seminar-publish-table-head td {
  border-top: none;
  border-bottom: 2px solid #3E3A39;
}
@media (max-width: 559px) {
  #seminar .seminar-publish-table-head td {
    font-size: 1.2rem;
  }
}
#seminar .seminar-publish-table-title {
  width: auto;
}
@media (max-width: 559px) {
  #seminar .seminar-publish-title {
    font-size: 1.6rem;
    padding-bottom: 12px;
  }
}
#seminar .seminar-publish-magazin {
  width: 350px;
}
@media (max-width: 959px) {
  #seminar .seminar-publish-magazin {
    width: auto;
  }
}
#seminar .seminar-publish-publisher {
  width: 170px;
}
#seminar .seminar-publish-writer {
  width: 100px;
}
#seminar .seminar-publish-table-left {
  border-left: none;
}
#seminar .seminar-publish-table-left a {
  color: #383838;
}
#seminar .seminar-publish-table-left a:hover {
  color: #EA5550;
}
#seminar .seminar-publish-table-left a:before {
  content: "PDF";
  background: #EA5550;
  color: #fff;
  display: inline-block;
  padding: 1px 5px;
  border-radius: 4px;
  font-size: 1.2rem;
  margin-right: 5px;
  letter-spacing: 0.1em;
}
#seminar .seminar-publish-table-right {
  border-right: none;
}
@media (max-width: 559px) {
  #seminar .seminar-publish {
    padding-bottom: 75px;
  }
}
#seminar .seminar-publish:after {
  content: "";
  width: 1000vh;
  height: 1px;
  background: #ea5550;
  position: absolute;
  bottom: 0;
  left: 60%;
}
@media (max-width: 559px) {
  #seminar .seminar-publish:after {
    content: none;
  }
}
#seminar .seminar-book-list {
  display: flex;
  width: 100%;
  margin-bottom: 30px;
  flex-wrap: wrap;
}
#seminar .seminar-book-detail {
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  align-items: center;
  width: 48%;
  justify-content: space-between;
  margin-bottom: 60px;
  margin-left: 4%;
}
@media (max-width: 559px) {
  #seminar .seminar-book-detail {
    width: auto;
    margin-left: 0;
    margin-bottom: 15px;
  }
}
#seminar .seminar-book-detail:nth-child(2n+1) {
  margin-left: 0;
}
#seminar .seminar-book-detail-image {
  width: 130px;
}
@media (max-width: 559px) {
  #seminar .seminar-book-detail-image {
    width: 90px;
  }
}
#seminar .seminar-book-detail-image a {
  display: block;
}
#seminar .seminar-book-detail-contents {
  width: calc(100% - 150px);
  -webkit-transition: right 1s cubic-bezier(0.1, 0.82, 0.1, 1) 1s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s;
  transition: right 1s cubic-bezier(0.1, 0.82, 0.1, 1) 1s, opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s;
  text-align: left;
}
@media (max-width: 559px) {
  #seminar .seminar-book-detail-contents {
    margin-right: 10%;
  }
}
#seminar h3.seminar-book-detail-post:hover {
  color: #3E3A39;
}
#seminar .seminar-book-detail-post {
  padding-top: 10px;
  font-size: 1.8rem;
  margin-bottom: 15px;
}
#seminar .seminar-book-detail-image img {
  width: 100%;
  height: auto;
}
#seminar .seminar-book-detail-other {
  font-size: 1.6rem;
  line-height: 1.5;
}
@media (max-width: 559px) {
  #seminar .seminar-book-detail-other {
    font-size: 1.2rem;
  }
}
#seminar .seminar-book {
  /*&:after {
  	content: "";
  	width: 1000vh;
  	height: 1px;
  	background: #ea5550;
  	position: absolute;
  	bottom: 0;
  	left: 60%;
  }*/
}
@media (max-width: 559px) {
  #seminar .seminar-book {
    margin-bottom: 80px;
  }
}
#seminar .btn-seminar {
  width: 220px;
}
#seminar .seminar-link {
  text-align: right;
  margin-top: 20px;
}
@media (max-width: 559px) {
  #seminar .seminar-link {
    text-align: center;
  }
}
#seminar .seminar-link a {
  color: #EA5550;
}
#seminar .active:after {
  background: #EA5550;
}

#privacy .title-text-privacy {
  font-size: 4.2rem;
  color: #3E3A39;
  text-align: center;
  position: static;
}
#privacy .title-text-ja {
  font-size: 3.2rem;
}
@media (max-width: 559px) {
  #privacy .title-text-ja {
    font-size: 2.4rem;
  }
}
#privacy .page-body p {
  text-align: left;
}
@media (max-width: 559px) {
  #privacy .page-body p {
    margin-bottom: 20px;
  }
}
#privacy .page-body ul {
  margin-bottom: 30px;
  text-align: left;
}
@media (max-width: 559px) {
  #privacy .page-body h2 {
    padding-top: 20px;
    margin-bottom: 20px;
    font-size: 1.7rem;
  }
}
#privacy body h2 {
  font-size: 2.4rem;
  padding-top: 22px;
}
#privacy .primary-num {
  font-family: "Roboto";
}
#privacy .page-body h2 {
  text-align: left;
  padding-top: 30px;
}
#privacy .box {
  border: 3px double #ea5550;
  padding: 30px;
  margin-bottom: 30px;
  line-height: 1.7;
}
#privacy .box h2,
#privacy .box h3,
#privacy .box h4,
#privacy .box h5 {
  font-size: 2.4rem;
  margin-bottom: 15px;
  padding-top: 0;
  text-align: left;
}
@media (max-width: 559px) {
  #privacy .box h2,
#privacy .box h3,
#privacy .box h4,
#privacy .box h5 {
    font-size: 2rem;
  }
}
#privacy .box h3:hover {
  color: #3E3A39;
}
#privacy .gnavi-list li:nth-child(5) a::before {
  background: #ea5550;
}
@media (max-width: 959px) {
  #privacy .page-title {
    height: auto;
    width: auto;
    margin-bottom: auto;
  }
}
@media (max-width: 959px) {
  #contact .form-body {
    padding-left: 0px;
    padding-right: 0px;
  }
}
#contact .page-body p {
  text-align: left;
}
@media (max-width: 559px) {
  #contact .page-body p {
    font-size: 1.3rem;
  }
}
#contact .page-body h2 {
  text-align: left;
}
@media (max-width: 959px) {
  #contact .page-title {
    margin-bottom: 120px;
  }
}
#contact .title-text-contact .title-text-ja {
  font-size: 3.2rem;
}
#contact table {
  font-size: 1.5rem;
  line-height: 1.7;
}
#contact table td,
#contact table th {
  border: none;
  padding: 40px 0;
  border-bottom: 1px solid #3c4559;
  vertical-align: top;
}
@media (max-width: 559px) {
  #contact table td,
#contact table th {
    padding: 0;
    border-bottom: none;
  }
}
#contact table th {
  font-weight: 700;
  width: 240px;
}
@media (max-width: 959px) {
  #contact table th {
    width: 190px;
  }
}
@media (max-width: 559px) {
  #contact table tr {
    padding: 20px 0;
  }
}
#contact table .require {
  font-size: 1.2rem;
  font-weight: 400;
  color: #fff;
  background: #d82f3f;
  line-height: 1;
  padding: 3px 5px;
  float: right;
  margin: 5px 25px 0 0;
}
@media (max-width: 959px) {
  #contact table .require {
    font-size: 1rem;
    padding: 2px 3px;
  }
}
#contact table .input-box {
  display: inline-block;
}
#contact table .form-name {
  width: 120px;
}
#contact table .form-comment {
  font-size: 1.3rem;
  line-height: 1.5;
  color: #77838f;
  display: block;
  padding-top: 5px;
}
@media (max-width: 559px) {
  #contact table .form-comment {
    font-size: 1rem;
    padding: 2px 3px;
  }
}
#contact input[type=text],
#contact input[type=number],
#contact input[type=password],
#contact input[type=email],
#contact textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  border: 1px solid #d1d1d1;
  padding: 5px 7px;
  border-radius: 1px;
  font-size: 1.5rem;
  background: 0 0;
}
#contact .contact-tb #sei,
#contact .contact-tb #sei2,
#contact .contact-tb #seikana,
#contact .contact-tb #seikana2 {
  margin-right: 15px;
}
#contact .contact-tb #zip1,
#contact .contact-tb #zip2 {
  margin: 0 3px 15px;
  width: 60px;
}
#contact .contact-tb #fax,
#contact .contact-tb #tel,
#contact .contact-tb #tel2,
#contact .contact-tb #tel3 {
  width: 180px;
}
#contact .contact-tb #ckemail,
#contact .contact-tb #ckemail2 {
  margin-top: 15px;
}
#contact .contact-tb #comment {
  height: 150px;
  width: 100%;
}
#contact .contact-tb .chkrequired::placeholder {
  font-size: 1.3rem;
}
#contact .contact-tb #corpname,
#contact .contact-tb #department,
#contact .contact-tb #corpname-title,
#contact .contact-tb #email,
#contact .contact-tb #ckemail {
  width: 75%;
}
#contact .contact-tb #address {
  margin-right: 10px;
}
#contact .btn-area {
  display: block;
}
#contact .btn-area p {
  text-align: center;
}
#contact .btn-area a {
  color: #EA5550;
}
@media (max-width: 559px) {
  #contact .confirm-btn {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
}
@media (max-width: 959px) {
  #contact .confirm-btn #form-submit {
    width: 240px;
    letter-spacing: 0.05em;
  }
}
@media (max-width: 559px) {
  #contact .confirm-btn #form-submit {
    width: 80%;
    margin-bottom: 30px;
  }
}
#contact #form-submit {
  display: inline-block;
  font-size: 2.1rem;
  color: #fff;
  width: 425px;
  padding: 25px 0;
  line-height: 1;
  background: #EA5550;
  -webkit-transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  letter-spacing: 0.2em;
  text-indent: 0.2em;
  border: none;
  border-radius: 8px;
}
@media (max-width: 559px) {
  #contact #form-submit {
    width: 90%;
  }
}
#contact #form-submit:hover {
  cursor: pointer;
}
#contact #form-back {
  display: inline-block;
  font-size: 1.6rem;
  color: #fff;
  padding: 14px 20px;
  line-height: 1;
  background: #888;
  -webkit-transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  border: none;
  border-radius: 8px;
  margin-right: 20px;
}
@media (max-width: 559px) {
  #contact #form-back {
    margin-right: 0px;
    width: 80%;
  }
}
#contact #form-back:hover {
  cursor: pointer;
}
#contact .thanks-box h2 {
  padding-bottom: 50px;
  text-align: center;
}
@media (max-width: 559px) {
  #contact .thanks-box h2 {
    padding-bottom: 0;
  }
}
#contact .thanks-box p {
  text-align: center;
}
#contact .thanks-box a {
  color: #EA5550;
}
#contact .tbdisp {
  display: none;
}
@media (max-width: 559px) {
  #contact .tbdisp {
    display: block;
  }
}
#contact .err {
  width: fit-content;
}

#service .title-image p {
  position: absolute;
  right: -11%;
  bottom: -10%;
  z-index: 2;
  color: #fff;
}
@media screen and (max-width: 1280px) {
  #service .title-image p {
    right: 3%;
  }
}
@media (max-width: 959px) {
  #service .title-image p {
    font-size: 1.4rem;
  }
}
@media (max-width: 559px) {
  #service .title-image p {
    font-size: 1.2rem;
    bottom: -20%;
  }
}
#service .service-detail {
  text-align: left;
}
@media (max-width: 959px) {
  #service .service-detail {
    padding-top: 20px;
  }
}
@media (max-width: 559px) {
  #service .service-detail {
    padding-top: 10px;
  }
}
#service .service-detail h2 {
  margin-bottom: 20px;
}
#service .service-detail h2:after {
  content: "";
  display: block;
  position: absolute;
  -webkit-transform: translate(0);
  transform: translate(0);
  width: 100%;
  height: 1px;
  background-color: #e65754;
}
#service .service-detail p {
  margin-bottom: 50px;
}
@media (max-width: 559px) {
  #service .service-detail p {
    margin-bottom: 30px;
  }
}
#service .service-detail .ma-span {
  margin-bottom: 35px;
}
@media (max-width: 959px) {
  #service .service-detail .ma-span {
    margin-bottom: 20px;
  }
}
@media (max-width: 559px) {
  #service .service-detail .ma-span {
    margin-bottom: 10px;
  }
}

#news .page-body ul {
  text-align: left;
}
@media screen and (max-width: 1280px) {
  #news .info-list {
    margin-left: 50px;
  }
}
@media (max-width: 959px) {
  #news .info-list {
    padding-top: 20px;
    margin-left: auto;
  }
}
#news .info-list li {
  margin-bottom: 10px;
}
#news .info-text a {
  color: #3E3A39;
}
#news span.info-date {
  margin-right: 20px;
}
#news .entry-title {
  font-size: 3.2rem;
  line-height: 1.2;
  color: #2a2a2f;
  margin-bottom: 10px;
  border-bottom: 1px solid #EA5550;
  padding-bottom: 20px;
  text-align: left;
}
#news .entry-date {
  text-align: left;
}
#news .date-wrap {
  margin-bottom: 40px;
}
#news .entry-body {
  margin-top: 30px;
  padding-bottom: 50px;
  text-align: left;
}
#news .entry-navi {
  text-align: right;
}
#news .entry-back {
  margin-bottom: 30px;
}
#news .entry-back a {
  color: #EA5550;
}
#news .entry-prev {
  text-align: left;
}
#news .entry-prev a {
  color: #EA5550;
}
#news .entry-next {
  text-align: right;
  margin-top: -30px;
}
#news .entry-next a {
  color: #EA5550;
}

@media (max-width: 959px) {
  #sitemap .page-title {
    margin-bottom: 50px;
  }
}
#sitemap .title-text-privacy {
  font-size: 4.2rem;
  color: #3E3A39;
  text-align: center;
  position: static;
}
#sitemap .title-text-ja {
  font-size: 3.2rem;
}
#sitemap .sitemap-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  text-align: left;
  margin-left: 100px;
}
@media (max-width: 959px) {
  #sitemap .sitemap-list {
    margin-left: 50px;
    margin-bottom: 70px;
  }
}
@media (max-width: 559px) {
  #sitemap .sitemap-list {
    margin-left: auto;
  }
}
#sitemap .sitemap-list li:first-child {
  padding-top: 0 !important;
}
#sitemap .sitemap-list li {
  padding-left: 0;
}
#sitemap .sitemap-list a {
  color: #3E3A39;
}
#sitemap .sitemap-list a:hover {
  color: #EA5550;
}
#sitemap .sitemap-first {
  width: 100%;
  padding-top: 30px;
  font-weight: 700;
}
#sitemap .sitemap-semiar {
  padding-bottom: 10px;
}
#sitemap .sitemap-second {
  width: 33.33%;
  font-size: 1.3rem;
}
@media (max-width: 959px) {
  #sitemap .sitemap-second {
    width: 49%;
  }
}
@media (max-width: 559px) {
  #sitemap .sitemap-second {
    font-size: 1.2rem;
  }
}
#sitemap .sitemap-span1 a {
  padding-left: 12px;
}
@media (max-width: 959px) {
  #sitemap .sitemap-span3 a {
    padding-left: 12px;
  }
}

.top-tit {
  color: #3E3A39;
  line-height: 1;
}
.top-tit-en {
  font-size: 4.4rem;
  display: block;
  letter-spacing: 0.02em;
}
@media (max-width: 559px) {
  .top-tit-en {
    font-size: 3.2rem;
  }
}
.top-tit-jp {
  font-size: 1.4rem;
  display: block;
  margin-top: 13px;
}
@media (max-width: 559px) {
  .top-tit-jp {
    font-size: 1.3rem;
    margin-top: 9px;
  }
}

.key {
  padding-top: 80px;
  position: relative;
  z-index: 2;
}
@media (max-width: 959px) {
  .key {
    padding-top: 20px;
  }
}
@media (max-width: 559px) {
  .key {
    padding-top: 0;
  }
}

.key-slide {
  height: 448px;
  width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  overflow: hidden;
}
@media screen and (max-width: 1280px) {
  .key-slide {
    width: 94vw;
  }
}
@media (max-width: 959px) {
  .key-slide {
    width: 100vw;
  }
}
@media (max-width: 559px) {
  .key-slide {
    height: 75vw;
  }
}

.key-slide-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  z-index: 1;
  white-space: nowrap;
}
.key-slide-title .key-tit {
  color: #fff;
  text-align: center;
  line-height: 1;
}
@media (max-width: 959px) {
  .key-slide-title .key-tit {
    line-height: 1.3;
  }
}
.key-slide-title .key-tit-jp {
  display: block;
  font-size: 3.6rem;
  font-weight: 700;
  text-shadow: 0 6px 6px rgba(0, 0, 0, 0.4);
}
@media (max-width: 559px) {
  .key-slide-title .key-tit-jp {
    font-size: 2.4rem;
  }
}
.key-slide-title .key-tit-jp > span {
  font-weight: 300;
}
.key-slide-title .key-tit-en {
  display: block;
  margin-top: 24px;
  font-size: 1.8rem;
}
@media (max-width: 559px) {
  .key-slide-title .key-tit-en {
    font-size: 1.3rem;
  }
}

.swiper-pagination {
  position: relative;
  z-index: 2;
  bottom: 0;
  margin-top: 10px;
}

.swiper-pagination-bullet {
  width: 20px;
  height: 10px;
  background: none;
  position: relative;
  opacity: 1;
  margin: 0 5px;
}
.swiper-pagination-bullet:before {
  content: "";
  width: 100%;
  height: 2px;
  border-radius: 1px;
  position: absolute;
  top: 4px;
  left: 0;
  background: #707070;
  transition: background 0.3s cubic-bezier(0.25, 0.48, 0.63, 0.93);
}
.swiper-pagination-bullet:hover:before {
  background: #EA5550;
}
.swiper-pagination-bullet.swiper-pagination-bullet-active:before {
  background: #EA5550;
}

.key-bg {
  width: 540px;
  height: calc(100% + 140px);
  background: #EA5550;
  position: absolute;
  top: -140px;
  right: 0;
  z-index: 1;
  transform: skew(-10deg);
  transform-origin: right bottom;
}
@media screen and (max-width: 1200px) {
  .key-bg {
    width: 440px;
    height: calc(100% + 100px);
    top: -100px;
  }
}
@media (max-width: 959px) {
  .key-bg {
    width: 40vw;
  }
}
@media (max-width: 559px) {
  .key-bg {
    width: 50vw;
  }
}
.key-bg .key-bg-svg {
  width: 110%;
  height: auto;
  position: absolute;
  bottom: 0;
  left: 5%;
  transform: skew(10deg);
}
@media (max-width: 959px) {
  .key-bg .key-bg-svg {
    width: 130%;
  }
}
@media (max-width: 559px) {
  .key-bg .key-bg-svg {
    width: 110%;
  }
}

.key1 {
  background: url(../../images/key5.jpg) center center no-repeat;
  background-size: cover;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /*.key-tit{
  	color: #fff;text-align: center;line-height: 1;
  	@include for-tb(){
  		line-height: 1.3;
  	}
  }
  .key-tit-jp{
  	display: block;font-size: 3.6rem;font-weight: 700;	
  	text-shadow: 0 6px 6px rgba(0,0,0,0.4);
  	@include for-sp(){
  		font-size: 2.4rem;
  	}
  	>span{
  		font-weight: 300;
  	}

  }
  .key-tit-en{
  	display: block;margin-top: 24px;
  	@extend .prompt;font-size: 1.8rem;
  	@include for-sp(){
  		font-size: 1.3rem;
  	}
  }*/
}

.key2 {
  background: url(../../images/key6.jpg) center center no-repeat;
  background-size: cover;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.key3 {
  background: url(../../images/key7.jpg) center center no-repeat;
  background-size: cover;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.key4 {
  background: url(../../images/key8.jpg) center center no-repeat;
  background-size: cover;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-caption {
  position: relative;
  color: #fff;
  left: 40%;
  top: 45%;
  opacity: 0.6;
  font-size: 1.2rem;
  letter-spacing: 0.05em;
}
@media (max-width: 959px) {
  .swiper-caption {
    left: 37%;
  }
}
@media (max-width: 559px) {
  .swiper-caption {
    font-size: 1rem;
    left: 28%;
  }
}

.top-news-main {
  max-width: 1000px;
  margin: 0 auto;
  z-index: 3;
  position: relative;
}
@media (max-width: 959px) {
  .top-news-main {
    max-width: 84vw;
  }
}
@media (max-width: 559px) {
  .top-news-main {
    width: 80%;
    padding: 0 0 40px;
  }
}
.top-news-main a {
  display: block;
  position: relative;
  color: #3e3a39;
  font-size: 24px;
}
@media (max-width: 559px) {
  .top-news-main a {
    font-size: 17px;
    padding-top: 15px;
  }
}
.top-news-main a::before {
  position: absolute;
  top: 17px;
  left: 0;
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  background: #3e3a39;
}
@media (max-width: 559px) {
  .top-news-main a::before {
    top: 12px;
    width: 10px;
    height: 10px;
  }
}
.top-news-main .top-news-list li {
  padding: 22px 0;
  border-bottom: 1px solid #ea5550;
  display: flex;
  align-items: flex-start;
  gap: 20px;
}
@media (max-width: 559px) {
  .top-news-main .top-news-list li {
    display: block;
  }
}
.top-news-main .top-news-list .border-bottom-none {
  border-bottom: none;
}

.top-news-main-tit {
  padding: 9px 10px;
  font-family: Prompt, sans-serif;
  font-weight: 500;
  font-size: 20px;
  white-space: nowrap;
  line-height: 1;
  background-color: #ea5550;
  color: #fff;
  margin-top: 5px;
}
@media (max-width: 559px) {
  .top-news-main-tit {
    font-size: 16px;
    padding: 6px 8px;
  }
}

.top-news {
  width: 1000px;
  margin: 0 auto;
  padding: 75px 0 104px;
  position: relative;
  z-index: 3;
}
@media (max-width: 959px) {
  .top-news {
    width: 84vw;
  }
}
@media (max-width: 559px) {
  .top-news {
    width: 80vw;
    padding: 30px 0 50px;
  }
}

.top-news-tit {
  position: relative;
}
.top-news-tit:before {
  content: "";
  width: 854px;
  height: 1px;
  background: #EA5550;
  position: absolute;
  left: 146px;
  top: 32px;
}
@media screen and (max-width: 1560px) {
  .top-news-tit:before {
    width: 640px;
  }
}
@media (max-width: 959px) {
  .top-news-tit:before {
    width: 40vw;
  }
}
@media (max-width: 559px) {
  .top-news-tit:before {
    left: 108px;
    width: 90px;
  }
}

.anm .top-news-tit:before {
  transform: scaleX(0);
  transform-origin: right center;
  transition: transform 0.5s cubic-bezier(0.25, 0.48, 0.63, 0.93) 0.5s;
}
.anm.is-show .top-news-tit:before {
  transform: scaleX(1);
}

.top-news-list {
  margin-top: 32px;
}
@media (max-width: 559px) {
  .top-news-list {
    margin-top: 24px;
  }
}
.top-news-list li {
  margin-bottom: 10px;
}
@media (max-width: 559px) {
  .top-news-list li {
    margin-top: 5px;
  }
}

.top-news-date {
  letter-spacing: 0.05em;
  display: inline-block;
  margin-right: 12px;
}
@media (max-width: 559px) {
  .top-news-date {
    font-size: 1.2rem;
    display: block;
    line-height: 1;
  }
}

.top-news-text a {
  color: #383838;
}

.info-text a {
  color: #3E3A39;
}

.top-about {
  position: relative;
  padding: 16px 0 100px;
}
@media screen and (max-width: 1360px) {
  .top-about {
    padding-top: 70px;
  }
}
@media (max-width: 559px) {
  .top-about {
    padding: 40px 0 50px;
  }
}
.top-about:before {
  content: "";
  width: 46.7vw;
  height: 1px;
  background: #EA5550;
  position: absolute;
  left: 0;
  top: 0;
}
@media (max-width: 959px) {
  .top-about:before {
    width: 40vw;
  }
}

.top-about.anm:before {
  transform: scaleX(0);
  transform-origin: left center;
  transition: transform 0.5s cubic-bezier(0.25, 0.48, 0.63, 0.93) 0.5s;
}
.top-about.anm.is-show:before {
  transform: scaleX(1);
}

.top-about-box {
  width: 1000px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}
@media (max-width: 959px) {
  .top-about-box {
    width: 84vw;
    display: block;
  }
}

.top-about-image {
  width: 584px;
  margin-left: -146px;
  position: relative;
  margin-top: 35px;
}
@media screen and (max-width: 1200px) {
  .top-about-image {
    margin-left: calc((1000px - 100vw) / 2);
    width: 520px;
  }
  .top-about-image img {
    width: 100%;
    height: auto;
  }
}
@media (max-width: 959px) {
  .top-about-image {
    width: 80%;
    margin-left: 0;
  }
}
@media (max-width: 559px) {
  .top-about-image {
    width: 100%;
  }
}

.top-about-btn {
  position: absolute;
  top: 223px;
  right: -10px;
}
@media screen and (max-width: 1200px) {
  .top-about-btn {
    top: 190px;
  }
}
@media (max-width: 959px) {
  .top-about-btn {
    top: auto;
    bottom: 30px;
  }
}
.top-about-btn .btn {
  width: 200px;
  height: 46px;
  font-size: 2rem;
}
@media (max-width: 559px) {
  .top-about-btn .btn {
    width: 180px;
    font-size: 1.7rem;
  }
}
.top-about-btn .btn:after {
  opacity: 0.93;
}

.top-about-contents {
  width: 524px;
  margin-right: -44px;
}
@media screen and (max-width: 1200px) {
  .top-about-contents {
    width: 480px;
    margin-right: 0;
  }
}
@media (max-width: 959px) {
  .top-about-contents {
    width: 100%;
  }
}

.top-about-text {
  text-align: justify;
  margin-top: 40px;
}
@media (max-width: 559px) {
  .top-about-text {
    margin-top: 24px;
  }
}

.top-middle {
  position: relative;
}
.top-middle:before {
  content: "";
  width: 400px;
  height: 100%;
  background: #EA5550;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transform: skew(10deg);
  transform-origin: left bottom;
}
@media (max-width: 959px) {
  .top-middle:before {
    width: 40vw;
  }
}
@media (max-width: 559px) {
  .top-middle:before {
    width: 50vw;
  }
}

.top-service {
  background: #F5F5F5;
  padding: 75px 0 90px;
}
@media (max-width: 559px) {
  .top-service {
    padding: 40px 0 50px;
  }
}

.top-service-box {
  width: 1000px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}
@media (max-width: 959px) {
  .top-service-box {
    width: 84vw;
  }
}
@media (max-width: 559px) {
  .top-service-box {
    width: 80vw;
  }
}

.top-service-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  justify-content: center;
}
@media (max-width: 559px) {
  .top-service-list {
    justify-content: space-between;
  }
}
.top-service-list li {
  width: 40%;
  margin-top: 15px;
  line-height: 1;
  margin-left: 13px;
}
@media (max-width: 559px) {
  .top-service-list li {
    width: 48%;
    margin-left: 0 !important;
    margin-top: 10px;
  }
}
.top-service-list .btn {
  padding: 17px 0;
  font-size: 2.2rem;
}
@media (max-width: 959px) {
  .top-service-list .btn {
    font-size: 1.9rem;
  }
}
@media (max-width: 559px) {
  .top-service-list .btn {
    font-size: 1.4rem;
  }
}
.top-service-list .btn .btn-label {
  padding-right: 0;
}
.top-service-list .btn .btn-label:before {
  display: none;
}
.top-service-list .btn:before {
  background: none;
}

.top-service-btn {
  text-align: right;
  margin-top: 50px;
}
.top-service-btn .btn {
  width: 200px;
  height: 46px;
  font-size: 2rem;
}

.top-news-btn {
  margin-top: 50px;
}
.top-news-btn .btn {
  width: 200px;
  height: 46px;
  font-size: 1.8rem;
}
.top-news-btn .btn-top-news {
  margin-right: 100px;
}

.top-staff {
  position: relative;
  padding: 150px 0 80px;
}
@media (max-width: 959px) {
  .top-staff {
    padding-top: 70px;
  }
}
@media (max-width: 559px) {
  .top-staff {
    padding: 40px 0 50px;
  }
}
.top-staff:before {
  content: "";
  width: 65.1vw;
  height: 1px;
  background: #EA5550;
  position: absolute;
  right: 0;
  bottom: 0;
}
@media (max-width: 959px) {
  .top-staff:before {
    width: 40vw;
  }
}

.top-staff.anm:before {
  transform: scaleX(0);
  transform-origin: right center;
  transition: transform 0.5s cubic-bezier(0.25, 0.48, 0.63, 0.93) 0.8s;
}
.top-staff.anm.is-show:before {
  transform: scaleX(1);
}

.top-staff-box {
  width: 1000px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  position: relative;
  z-index: 2;
}
@media (max-width: 959px) {
  .top-staff-box {
    width: 84vw;
    display: block;
  }
}

.top-staff-image {
  width: 584px;
  margin-left: -146px;
  position: relative;
}
@media screen and (max-width: 1200px) {
  .top-staff-image {
    margin-left: calc((1000px - 100vw) / 2);
    width: 520px;
  }
  .top-staff-image img {
    width: 100%;
    height: auto;
  }
}
@media (max-width: 959px) {
  .top-staff-image {
    width: 100%;
    padding-left: 20%;
    margin-top: 35px;
    margin-left: 0;
  }
}
@media (max-width: 559px) {
  .top-staff-image {
    padding-left: 0;
  }
}

.top-staff-btn {
  position: absolute;
  top: 223px;
  right: -10px;
}
@media screen and (max-width: 1200px) {
  .top-staff-btn {
    top: 190px;
  }
}
@media (max-width: 959px) {
  .top-staff-btn {
    top: auto;
    bottom: 30px;
  }
}
.top-staff-btn .btn {
  width: 220px;
  height: 46px;
  padding: 13px 0;
  font-size: 2rem;
}
@media (max-width: 559px) {
  .top-staff-btn .btn {
    width: 200px;
    font-size: 1.7rem;
  }
}
.top-staff-btn .btn:after {
  opacity: 0.93;
}

.top-staff-contents {
  width: 480px;
}
@media (max-width: 959px) {
  .top-staff-contents {
    width: 100%;
  }
}

.top-staff-text {
  text-align: justify;
  margin-top: 60px;
}
@media (max-width: 959px) {
  .top-staff-text {
    margin-top: 40px;
  }
}
@media (max-width: 559px) {
  .top-staff-text {
    margin-top: 24px;
  }
}

.top-seminar {
  position: relative;
  padding: 90px 0 110px;
}
@media (max-width: 559px) {
  .top-seminar {
    padding: 40px 0 50px;
  }
}
.top-seminar:before {
  content: "";
  width: 60.4vw;
  height: 1px;
  background: #EA5550;
  position: absolute;
  left: 0;
  bottom: 0;
}
@media (max-width: 959px) {
  .top-seminar:before {
    width: 40vw;
  }
}

.top-seminar.anm:before {
  transform: scaleX(0);
  transform-origin: left center;
  transition: transform 0.5s cubic-bezier(0.25, 0.48, 0.63, 0.93) 0.8s;
}
.top-seminar.anm.is-show:before {
  transform: scaleX(1);
}

.top-seminar-box {
  width: 1000px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 2;
}
@media (max-width: 959px) {
  .top-seminar-box {
    display: block;
    width: 84vw;
  }
}

.top-seminar-image {
  width: 584px;
  margin-right: -146px;
  position: relative;
}
@media screen and (max-width: 1200px) {
  .top-seminar-image {
    margin-right: calc((1000px - 100vw) / 2);
    width: 520px;
  }
  .top-seminar-image img {
    width: 100%;
    height: auto;
  }
}
@media (max-width: 959px) {
  .top-seminar-image {
    width: 80%;
    margin-top: 35px;
  }
}
@media (max-width: 559px) {
  .top-seminar-image {
    width: 100%;
  }
}

.top-seminar-btn {
  position: absolute;
  top: 27px;
  left: -18px;
}
.top-seminar-btn .btn {
  width: 200px;
  padding: 13px 0;
  font-size: 2rem;
}
@media (max-width: 559px) {
  .top-seminar-btn .btn {
    width: 180px;
    font-size: 1.7rem;
  }
}
.top-seminar-btn .btn:after {
  opacity: 0.93;
}

.top-seminar-contents {
  width: 480px;
}
@media (max-width: 959px) {
  .top-seminar-contents {
    width: 100%;
  }
}

.top-seminar-text {
  text-align: justify;
  margin-top: 60px;
}
@media (max-width: 959px) {
  .top-seminar-text {
    margin-top: 40px;
  }
}

/*exValidation*/
.errMsg {
  margin-left: 1em;
  padding-bottom: 1px;
  display: block;
  line-height: 1.4;
  text-indent: -0.9em;
}

.formError {
  padding-bottom: 5px;
  display: block;
  margin-left: -20px;
}
.formError .formErrorContent {
  padding: 3px 8px;
  width: auto;
  position: relative;
  color: #fff;
  background: #EA5550;
  font-size: 1.2rem;
  _z-index: 1;
}
.formError .formErrorArrow {
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: -10px;
  left: 5px;
  _z-index: 0;
}
.formError .formErrorArrow div {
  margin: 0 auto;
  display: block;
  height: 1px;
  background: #EA5550;
  line-height: 0px;
  font-size: 0px;
}
.formError .formErrorArrow .line10 {
  width: 19px;
}
.formError .formErrorArrow .line9 {
  width: 17px;
}
.formError .formErrorArrow .line8 {
  width: 15px;
}
.formError .formErrorArrow .line7 {
  width: 13px;
}
.formError .formErrorArrow .line6 {
  width: 11px;
}
.formError .formErrorArrow .line5 {
  width: 9px;
}
.formError .formErrorArrow .line4 {
  width: 7px;
}
.formError .formErrorArrow .line3 {
  width: 5px;
}
.formError .formErrorArrow .line2 {
  width: 3px;
}
.formError .formErrorArrow .line1 {
  width: 1px;
}
.formError .fadeOut {
  opacity: 0.2;
}

.err {
  background: #ffecec !important;
}

.errMsg {
  padding-bottom: 1px;
  display: block;
  line-height: 1.4;
}

.formError {
  padding-top: 5px;
  display: block;
}
.formError .formErrorContent {
  padding: 3px 8px;
  width: auto;
  position: relative;
  color: #fff;
  background: #EA5550;
  font-size: 1.2rem;
  _z-index: 1;
}
.formError .formErrorArrow {
  display: none;
}
.formError .fadeOut {
  opacity: 0.2;
}

.err {
  background: #ffecec !important;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@media print {
  body {
    zoom: 0.65;
  }
  #wrapper {
    min-width: 0;
  }
  .anm {
    opacity: 1;
    top: 0;
  }
  #staff .career-tb-br {
    display: block !important;
  }
}